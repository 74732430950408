import { getApplicantsList } from '@/api/applicant'
import { mkMutationName } from '@/store/helper'
import {
  ApplicantArrayFilters,
  ApplicantStringFilters,
  ARRAY_FIELDS_MAP,
  STRING_FIELDS_MAP,
} from '@/components/module/resultList/constants'

export const setApplicantList = mkMutationName('set', 'applicationList')
export const concatApplicantsList = mkMutationName('concat', 'applicationList')
export const setNextPageMarker = mkMutationName(
  'set',
  'applicationList',
  'NextPageMarker',
)
export const setFilters = mkMutationName('set', 'applicationList', 'Filters')

export const templateFilters: ApplicantStringFilters & ApplicantArrayFilters = {
  ...STRING_FIELDS_MAP,
  ...ARRAY_FIELDS_MAP,
}

export default {
  state: {
    applicantList: {
      applicants: [],
      nextPageMarker: null,
    },
    filters: templateFilters,
  },
  mutations: {
    [setApplicantList](state, { applicants }) {
      state.applicantList.applicants = applicants
    },
    //use this one for infinite scroll
    [concatApplicantsList](state, { applicants }) {
      if (
        applicants[0]?.entityId &&
        applicants.some(newApplicant =>
          state.applicantList.applicants.some(
            oldApplicant => oldApplicant.entityId === newApplicant.entityId,
          ),
        )
      ) {
        return
      }
      state.applicantList.applicants = [
        ...state.applicantList.applicants,
        ...applicants,
      ]
    },
    [setNextPageMarker](state, { value }) {
      state.applicantList.nextPageMarker = value
    },
    [setFilters](state, { newFilters }) {
      state.filters = { ...state.filters, ...newFilters }
    },
  },
  actions: {
    async getApplicantsList(
      { commit, dispatch },
      { applicationStage, options },
    ) {
      try {
        const { data, nextPageMarker } = await getApplicantsList(
          applicationStage,
          options,
        )
        commit(setNextPageMarker, { value: nextPageMarker })
        commit(concatApplicantsList, { applicants: data })
        return { data, nextPageMarker }
      } catch (err) {
        commit(setNextPageMarker, { value: null })
        throw err
      }
    },
    updateFilters({ commit, dispatch }, { newFilters }) {
      commit(setFilters, { newFilters })
    },
    resetFilters({ commit }) {
      commit(setFilters, { newFilters: templateFilters })
    },
    clearApplicantsList({ commit }) {
      commit(setApplicantList, { applicants: [] })
      commit(setNextPageMarker, { value: null })
    },
  },
}
