





import { Vue, Component, Watch } from 'vue-property-decorator'
import PageWrapper from '@/frames/PageWrapper.vue'

@Component({
  components: { PageWrapper },
})
export default class App extends Vue {
  @Watch('$route')
  onRouteChange(to, from) {
    const title = to.meta.title
    if (title) document.title = `Frankie | ${title}`
  }
}
