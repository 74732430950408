<template lang="pug">
    //- needs to reemit focus and blur events from this text input
    //- needs to have a transparent cover element to detect and reemit click events
    input.input( autocomplete="false" :value='value || placeholder' readonly :disabled="disabled" )
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: [Array, String],
    disabled: Boolean
  },
  methods: {
    handleKeypress(e) {
      e.preventDefault();
    }
    // handleArrowKey(e){
    //     let key = e.keyCode;
    //     let arrowKeyCode = [37, 38,39,40, 13];
    //     if(key ==9) return
    //     if(!arrowKeyCode.includes(key)){
    //         e.preventDefault();
    //     } else {
    //         this.$emit('move', key)
    //     }
    // }
  }
};
</script>

<style lang="sass" scoped>
input.input
    color: transparent!important
    text-shadow: 0 0 0 $COLOR__BLACK
    padding: 6px 12px!important
    &:focus
        outline: none
</style>
