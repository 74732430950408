var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-menu",class:{toggled: _vm.isToggled}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"icon-wrapper",attrs:{"data-qa":"user-menu-dropdown"},on:{"click":_vm.toggle}},[(_vm.usernameDisplayIcon)?_c('div',{staticClass:"label-avatar"},[_vm._v(_vm._s(_vm.usernameDisplayIcon))]):_c('svgicon',{staticClass:"avatar",attrs:{"name":"user-avatar","width":"30px"}})],1),_c('dropdown-options-list',{staticClass:"collapsible-list",attrs:{"options":_vm.userOptions,"optionClass":_vm.optionClass},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_option = ref.option;
var label = ref_option.label;
var value = ref_option.value;
var isLink = ref_option.isLink;
var testId = ref_option.testId;
var i = ref.i;
return [(isLink)?_c('a',{staticClass:"link-option",attrs:{"href":value,"target":"_blank","data-qa":testId}},[_vm._v(_vm._s(label))]):_c('span',{attrs:{"data-qa":testId}},[_vm._v(_vm._s(label))])]}}]),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }