<template lang="pug">
    .label-wrapper(:class="{boldLabel}")
        .label-container( v-if="label" )
            label( v-bind="attrs" ) {{ label }}
        .content-container
            slot
        p.message-container( v-if="$slots.message" )
            slot( name="message" )
</template>
<style lang="sass" scoped>
$space: 5px
.label-wrapper
    font-size: 14px
    font-weight: 500
    line-height: normal
label
    font-weight: 500
    color: $COLOR__DARK_NAVY_80
.label-container
    padding-bottom: $space
.message-container
    padding: $space
    padding-top: 4px
.label-wrapper, .label-container, .content-container
    width: 100%
.boldLabel > .label-container label
    font-weight: 700
</style>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    boldLabel: Boolean
  },
  computed: {
    attrs() {
      const { label, ...attrs } = this.$attrs;
      return attrs;
    }
  }
};
</script>
