/* eslint-disable @typescript-eslint/no-extra-semi */
import { guardForRoute } from '@/router/guard'
import Router, { NavigationGuard } from 'vue-router'
import { FrankieApiClient } from '@frankieone/shared'
import Transparent from './TransparentRouterView'
import mapRouteToUrl from '@/utils/routeToUrlMapper'
import { LoginResponse } from '@frankieone/shared/dist/api/FrankieApiClient'
import { getToken, getPageData, setPageData } from '@/utils/pageDataStorage'
import { redirectToLogin } from '@/api/axiosInterceptors'
import {
  clearSavedUrl,
  getSavedUrl,
  mkAbsoluteUrlRelative,
} from '@/utils/urlStore'
import validate from 'validate.js'
import {
  hasAnyTxnFeature,
  TXN_PERMISSIONS,
} from '@/pages/applicantDetails/transactions/TransactionsPermissionHelpers'

let permissions = null
let isReactVerticalNavBar = null
const ONBOARDING_PAGE_PERMISSIONS = [
  'ffportal_onboarding_index',
  'ffportal_onboarding_index_data',
]
const APPLICANTS_PAGE_PERMISSIONS = [
  ...ONBOARDING_PAGE_PERMISSIONS,
  'ffportal_allentities_index',
  'ffportal_review_index',
]
const ONBOARDING_PAGE_ROUTE = mapRouteToUrl('ffportal_onboarding')

let fallbackRoute = () =>
  isReactVerticalNavBar ? 'ffportal_applicants' : 'ffportal_onboarding'

let fallbackRoutePermissions = () =>
  isReactVerticalNavBar
    ? APPLICANTS_PAGE_PERMISSIONS
    : ONBOARDING_PAGE_PERMISSIONS

const mkCommonApplicantRoutes = (from, otherChildren = []) => {
  const routes = []
  routes.push({
    path: 'manual-idv',
    component: () =>
      import(
        /* webpackChunkName: "manual-idv-page" */ '@/pages/manualIdentityVerification/components/ManualIdentityWrapper'
      ),
    name: `${from}ManualIDV`,
    meta: {
      title: 'Edit Customer Info',
      cardStyle: true,
    },
    props: route => ({
      from,
      applicantId: route.params.applicantId,
    }),
    beforeEnter: (to, from, next) => {
      guardForRoute({
        userAvailblePermission: permissions,
        requiredPermissions: ['ffportal_applicant_midv_index'],
        fallBackRoute: fallbackRoute(),
        fallbackRoutePermissions: fallbackRoutePermissions(),
        next,
      })
    },
  })
  routes.push({
    path: 'edit-business',
    component: () =>
      import(
        /* webpackChunkName: "edit-business-page" */ '@/pages/applicantDetails/ubo/components/BusinessUpdate'
      ),
    name: `${from}EditBusiness`,
    meta: {
      title: 'Edit Business Info',
      cardStyle: true,
    },
    props: route => ({
      from,
      applicantId: route.params.applicantId,
    }),
    beforeEnter: (to, from, next) => {
      guardForRoute({
        userAvailblePermission: permissions,
        requiredPermissions: ['ffportal_applicant_midv_index'],
        fallBackRoute: 'ffportal_onboarding',
        fallbackRoutePermissions: ONBOARDING_PAGE_PERMISSIONS,
        next,
      })
    },
  })
  routes.push({
    path: ':tab?',
    component: () =>
      import(
        /* webpackChunkName: "applicant-details-page" */ '@/pages/applicantDetails'
      ),
    name: `${from}ApplicantDetails`,
    meta: {
      title: 'Applicant Details',
      cardStyle: true,
    },
    props: route => ({
      from,
      applicantId: route.params.applicantId,
      selectedTabSlug: route.params.tab,
    }),
    beforeEnter: (to, from, next) => {
      guardForRoute({
        userAvailblePermission: permissions,
        requiredPermissions: ['ffportal_applicant_detail'],
        fallBackRoute: fallbackRoute(),
        fallbackRoutePermissions: fallbackRoutePermissions(),
        next,
      })
    },
  })

  return [
    {
      path: 'profile/:applicantId',
      component: () =>
        import(
          /* webpackChunkName: "applicant-details-fram" */ '@/pages/applicantDetails/ApplicantDetailsFrame'
        ),
      props: route => ({
        from,
        applicantId: route.params.applicantId,
        returnTo: [
          `${from}ManualIDV`,
          `${from}EditBusiness`,
          'resolveDuplicate',
          'resolveBlacklist',
        ].includes(route.name)
          ? 'details'
          : 'list', // if route.name is in this list, back button url leads to applicant details, otherwise to applicant list
      }),
      children: [...otherChildren, ...routes],
    },
  ]
}

/**
 * ROUTES
 */
const routes = []
routes.push({
  path: '/',
  redirect: '/onboarding',
})
if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/mock',
    component: () =>
      import(/* webpackChunkName: "resolve-duplicate-page" */ '@/Mocking'),
  })
}
routes.push({
  path: '/login',
  component: () =>
    import(
      /* webpackChunkName: "resolve-duplicate-page" */ '@/pages/security/Login'
    ),
  name: 'login',
  meta: { title: 'Login', isPublicPage: true },
})
routes.push({
  path: '/reset-password/:email',
  component: () =>
    import(
      /* webpackChunkName: "resolve-duplicate-page" */ '@/pages/security/ResetPasswordRequest'
    ),
  name: 'resetPassword',
  meta: { title: 'Password Reset', isPublicPage: true },
})
routes.push({
  path: '/new-password',
  component: () =>
    import(
      /* webpackChunkName: "resolve-duplicate-page" */ '@/pages/security/NewPassword'
    ),
  name: 'newPassword',
  meta: { title: 'New Password', isPublicPage: true },
})

const addOnboardingRoutes = (routes, features) => {
  routes.push({
    path: '/onboarding',
    component: Transparent,
    children: [
      ...mkCommonApplicantRoutes('onboarding', [
        {
          path: 'resolve-duplicate',
          component: () =>
            import(
              /* webpackChunkName: "resolve-duplicate-page" */ '@/pages/duplicate'
            ),
          name: 'resolveDuplicate',
          meta: { title: 'Resolve Duplicate' },
          props: route => ({
            duplicateType: 'duplicate',
          }),
        },
        {
          path: 'resolve-blacklist',
          component: () =>
            import(
              /* webpackChunkName: "resolve-blacklist-page" */ '@/pages/duplicate'
            ),
          name: 'resolveBlacklist',
          meta: { title: 'Resolve Blacklist' },
          props: route => ({
            duplicateType: 'blacklist',
          }),
        },
        {
          path: 'confirmed-duplicate',
          component: () =>
            import(
              /* webpackChunkName: "confirmed-duplicate-page" */ '@/pages/duplicate'
            ),
          name: 'confirmedDuplicate',
          meta: { title: 'Resolve Duplicate' },
          props: route => ({
            duplicateType: 'duplicate',
            confirmedEntity: true,
          }),
        },
        {
          path: 'confirmed-blacklist',
          component: () =>
            import(
              /* webpackChunkName: "confirmed-blacklist-page" */ '@/pages/duplicate'
            ),
          name: 'confirmedBlacklist',
          meta: { title: 'Resolve Blacklist' },
          props: route => ({
            duplicateType: 'blacklist',
            confirmedEntity: true,
          }),
        },
      ]),
      {
        path: '',
        name: 'onboardingList',
        component: () =>
          import(
            /* webpackChunkName: "profile-list-page" */ '@/pages/onboardingList/ApplicantList.vue'
          ),
        meta: {
          title: 'Onboarding List',
        },
        props: { applicationStage: 'onboarding' },
        beforeEnter: (to, from, next) => {
          guardForRoute({
            userAvailblePermission: permissions,
            requiredPermissions: ONBOARDING_PAGE_PERMISSIONS,
            fallBackRoute: fallbackRoute(),
            fallbackRoutePermissions: fallbackRoutePermissions(),
            next,
          })
        },
      },
    ],
  })
}

routes.push({
  path: '/new-profile',
  component: Transparent,
  meta: {
    title: 'New Profile',
    requiredPermissions: ['ffportal_applicant_new'],
  },
  children: [
    {
      path: '',
      redirect: { name: 'individual' },
    },
    {
      path: 'individual',
      name: 'individual',
      component: () =>
        import(/* webpackChunkName: "new-profile-page" */ '@/pages/profile'),
    },
    {
      path: 'business',
      name: 'business',
      component: () =>
        import(/* webpackChunkName: "new-profile-page" */ '@/pages/profile'),
    },
  ],
})

const addMonitoringRoutes = (routes, features) => {
  routes.push({
    path: '/monitoring',
    component: Transparent,
    children: [
      ...mkCommonApplicantRoutes('monitoring'),
      {
        path: '',
        name: 'monitoringList',
        component: () =>
          import(
            /* webpackChunkName: "profile-list-page" */ '@/pages/onboardingList/ApplicantList.vue'
          ),
        meta: {
          title: 'Monitoring List',
        },
        props: { applicationStage: 'monitoring' },
        beforeEnter: (to, from, next) => {
          guardForRoute({
            userAvailblePermission: permissions,
            requiredPermissions: [
              'ffportal_monitoring_index',
              'ffportal_monitoring_case_management_data',
            ],
            fallBackRoute: fallbackRoute(),
            fallbackRoutePermissions: fallbackRoutePermissions(),
            next,
          })
        },
      },
    ],
  })
}

routes.push({
  path: '/entities',
  component: Transparent,
  children: [
    ...mkCommonApplicantRoutes('entities'),
    {
      path: '',
      name: 'entitiesList',
      component: () =>
        import(
          /* webpackChunkName: "profile-list-page" */ '@/pages/onboardingList/ApplicantList.vue'
        ),
      meta: {
        title: 'Entities',
      },
      props: {
        applicationStage: '',
        route: 'ffportal_applicants_global_list',
        isGlobal: true,
        newNavBarStage: 'applicants',
      },
      beforeEnter: (to, from, next) => {
        guardForRoute({
          userAvailblePermission: permissions,
          requiredPermissions: APPLICANTS_PAGE_PERMISSIONS,
          fallBackRoute: fallbackRoute(),
          fallbackRoutePermissions: fallbackRoutePermissions(),
          next,
          isAnyPermission: true,
        })
      },
    },
  ],
})

routes.push({
  path: '/needs-review',
  component: Transparent,
  children: [
    ...mkCommonApplicantRoutes('needs-review'),
    {
      path: '',
      name: 'needsReview',
      component: () =>
        import(
          /* webpackChunkName: "profile-list-page" */ '@/pages/onboardingList/ApplicantList.vue'
        ),
      meta: {
        title: 'Needs Review',
      },
      props: {
        applicationStage: '',
        route: 'ffportal_applicants_global_list',
        newNavBarStage: 'needsReview',
        isGlobal: true,
      },
      beforeEnter: (to, from, next) => {
        guardForRoute({
          userAvailblePermission: permissions,
          requiredPermissions: [
            'ffportal_review_index',
            'ffportal_onboarding_index',
            'ffportal_onboarding_index_data',
          ],
          fallBackRoute: fallbackRoute(),
          fallbackRoutePermissions: fallbackRoutePermissions(),
          isAnyPermission: true,
          next,
        })
      },
    },
  ],
})

if (hasAnyTxnFeature()) {
  routes.push({
    path: '/transactions',
    component: Transparent,
    children: [
      ...mkCommonApplicantRoutes('transactions'),
      {
        path: '',
        name: 'transactionsList',
        component: () => import('@/pages/onboardingList/ApplicantList.vue'),
        meta: {
          title: 'Fraud/AML List',
        },
        props: { applicationStage: 'transactions' },
        beforeEnter: (to, from, next) => {
          guardForRoute({
            userAvailblePermission: permissions,
            requiredPermissions: [
              TXN_PERMISSIONS.AML,
              TXN_PERMISSIONS.FRAUD,
              TXN_PERMISSIONS.CUSTOMER,
            ],
            fallBackRoute: fallbackRoute(),
            fallbackRoutePermissions: fallbackRoutePermissions(),
            next,
          })
        },
      },
    ],
  })
}

routes.push({
  path: '/dashboard',
  component: Transparent,
  meta: { title: 'Dasboard' },
  children: [
    {
      path: '',
      name: 'DashboardTab',
      component: () =>
        import(/* webpackChunkName: "dashboard-page" */ '@/pages/dashboard'),
      props: () => ({ selectedTabSlug: 'dashboard' }),
      beforeEnter: (to, from, next) => {
        guardForRoute({
          userAvailblePermission: permissions,
          requiredPermissions: ['ffportal_reporting_dashboard'],
          fallBackRoute: fallbackRoute(),
          fallbackRoutePermissions: fallbackRoutePermissions(),
          next,
        })
      },
    },
  ],
})
routes.push({
  path: '/blocklist',
  component: Transparent,

  beforeEnter: (to, from, next) => {
    guardForRoute({
      userAvailblePermission: permissions,
      requiredPermissions: [],
      fallBackRoute: fallbackRoute(),
      fallbackRoutePermissions: fallbackRoutePermissions(),
      next,
    })
  },
  children: [
    ...mkCommonApplicantRoutes('blocklist'),
    {
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "blocklist-page" */ '@/pages/blocklist/BlocklistPage'
        ),
      name: 'management-blocklist',
    },
    {
      path: 'new',
      component: () =>
        import(
          /* webpackChunkName: "new-blocklist-page" */ '@/pages/blocklist/NewBlocklist.vue'
        ),
      name: 'new-blocklist',
    },
  ],
})

const addSettingsNavigation =(routes)=>{
routes.push({
  path: '/admin',
  component: Transparent,
  meta: { title: 'Admin Management' },
  children: [
    {
      path: '',
      redirect: { name: 'UsersView' },
    },
    {
      path: 'personal',
      component: Transparent,
      children: [
        {
          path: '',
          component: () =>
            import(
              /* webpackChunkName: "admin-settings-page" */ '@/pages/rolesSettings/AdminManagement'
            ),
          props: () => ({ selectedTabSlug: 'personal' }),
        },
      ],
    },
    {
      path: 'settings',
      component: Transparent,
      children: [
        {
          path: '',
          component: () =>
            import(
              /* webpackChunkName: "admin-settings-page" */ '@/pages/rolesSettings/AdminManagement'
            ),
          props: () => ({ selectedTabSlug: 'settings' }),
          beforeEnter: (to, from, next) => {
            guardForRoute({
              userAvailblePermission: permissions,
              requiredPermissions: ['ffportal_admin_fetch_settings_data'],
              fallBackRoute: fallbackRoute(),
              fallbackRoutePermissions: fallbackRoutePermissions(),
              next,
            })
          },
        },
      ],
    },
    {
      path: 'roles',
      component: Transparent,
      children: [
        {
          path: '',
          component: () =>
            import(
              /* webpackChunkName: "admin-roles-page" */ '@/pages/rolesSettings/AdminManagement'
            ),
          props: () => ({ selectedTabSlug: 'roles' }),
          beforeEnter: (to, from, next) => {
            guardForRoute({
              userAvailblePermission: permissions,
              requiredPermissions: ['ffportal_role_index.page'],
              fallBackRoute: fallbackRoute(),
              fallbackRoutePermissions: fallbackRoutePermissions(),
              next,
            })
          },
        },
        // Rendered in React
        // {
        //   path: 'new',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "roles-new-page" */ '@/pages/rolesSettings/RoleDetails'
        //     ),
        //   name: 'RoleNew',
        //   props: route => ({ pageType: 'role-new' }),
        // },
        // {
        //   path: ':roleId/edit',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "roles-edit-page" */ '@/pages/rolesSettings/RoleDetails'
        //     ),
        //   name: 'RoleEdit',
        //   props: route => ({
        //     roleId: route.params.roleId,
        //     pageType: 'role-edit',
        //   }),
        // },
        // {
        //   path: ':roleId/view',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "roles-edit-page" */ '@/pages/rolesSettings/RoleDetails'
        //     ),
        //   name: 'RoleView',
        //   props: route => ({
        //     roleId: route.params.roleId,
        //
        //     pageType: 'role-view',
        //   }),
        // },
        {
          path: ':roleId/permissions',
          component: () =>
            import(
              /* webpackChunkName: "roles-permissions-page" */ '@/pages/rolesSettings/PermissionMappingPage'
            ),
          name: 'RolePermissions',
          props: route => ({ roleId: route.params.roleId }),
        },
      ],
    },
    {
      path: 'settings',
      component: Transparent,
      children: [
        {
          path: '',
          component: () =>
            import(
              /* webpackChunkName: "admin-settings-page" */ '@/pages/rolesSettings/AdminManagement'
            ),
          props: () => ({ selectedTabSlug: 'settings' }),
          beforeEnter: (to, from, next) => {
            guardForRoute({
              userAvailblePermission: permissions,
              requiredPermissions: ['ffportal_admin_fetch_settings_data'],
              fallBackRoute: fallbackRoute(),
              fallbackRoutePermissions: fallbackRoutePermissions(),
              next,
            })
          },
        },
      ],
    },
    {
      path: 'users',
      component: Transparent,
      children: [
        {
          path: '',
          component: () =>
            import(
              /* webpackChunkName: "admin-users-page" */ '@/pages/rolesSettings/AdminManagement'
            ),
          name: 'UsersView',
          props: () => ({ selectedTabSlug: 'users' }),
          beforeEnter: (to, from, next) => {
            guardForRoute({
              userAvailblePermission: permissions,
              requiredPermissions: ['ffportal_user_index'],
              fallBackRoute: fallbackRoute(),
              fallbackRoutePermissions: fallbackRoutePermissions(),
              next,
            })
          },
        },
        {
          path: 'new',
          component: () =>
            import(
              /* webpackChunkName: "users-new-page" */ '@/pages/usersManagement/UsersNew'
            ),
          name: 'UserNew',
        },
        {
          path: ':userId/edit',
          component: () =>
            import(
              /* webpackChunkName: "users-edit-page" */ '@/pages/usersManagement/UsersEdit'
            ),
          name: 'UserEdit',
          props: route => ({
            userId: route.params.userId,
            userName: route.params.userName,
          }),
        },
        {
          path: ':userId/permissions',
          component: () =>
            import(
              /* webpackChunkName: "users-permissions-page" */ '@/pages/usersManagement/UserPermissions'
            ),
          name: 'UserPermissions',
          props: route => ({ userId: route.params.userId }),
        },
      ],
    },
  ],
})
}

export default function (options) {
  // default export

  if (options && isValidPageData(options.pageData)) {
    const {
      user: { roles = [] },
      permissions: actions = [],
    } = options.pageData
    permissions = [...actions, ...roles]
    isReactVerticalNavBar = options.pageData?.features?.verticalNavBar || false
  }

  // add old routes if no new nav
  addMonitoringRoutes(routes, options.pageData?.features)
  addOnboardingRoutes(routes, options.pageData?.features)

  if(!options.pageData?.features?.verticalNavBar){
    addSettingsNavigation(routes) // Adding only if vertical nav bar is not enabled
  }



  // add new '/applicant' routes if enabled

  const router = new Router({
    mode: 'history',
    base: '',
    routes,
  })

  router.beforeEach(
    beforeEachHandler({
      // external data store injected as parameter
      setPermissions(newPermissions: string[]) {
        permissions = newPermissions
      },
      setIsReactVerticalNavBar(newIsReactVerticalNavBar: boolean) {
        isReactVerticalNavBar = newIsReactVerticalNavBar
      },
      setPageData,
      setToken(token: string | null) {
        const storageKey = 'token'

        if (token === null) return localStorage.removeItem(storageKey)
        localStorage.setItem(storageKey, token)
      },
      getStoredUrl() {
        return getSavedUrl()
      },
      clearStoredUrl() {
        clearSavedUrl()
      },
      getToken,
      getPageData,
    }),
  )
  return router
}

type ExternalData = {
  setPermissions: (permissions: string[]) => void
  setIsReactVerticalNavBar: (isReactVerticalNavBar: boolean) => void
  setToken: (token: string) => void
  setPageData: (data: any) => void
  getToken: () => string
  getPageData: () => any
  clearStoredUrl: () => void
  getStoredUrl: () => string | null
}
type NavigationGuardParameters = Parameters<NavigationGuard<Vue>>
export const beforeEachHandler =
  (externalDataStore: ExternalData) =>
  async (...args: NavigationGuardParameters) => {
    const [to, from, next] = args
    const storedToken = externalDataStore.getToken()
    const storedPageData = externalDataStore.getPageData()
    // I believe the only required redirection is in case the token is invalid
    // const queryRedirect = to.query.redirect as string;
    const queryToken = to.query.token as string
    const queryEndSession = to.query['end_session']
    const defaultRedirectRoute = ONBOARDING_PAGE_ROUTE
    const storedRedirect =
      to.query['redirect'] ?? externalDataStore.getStoredUrl()
    if (queryToken) {
      // if it has a token being set as a query parameter
      // check if it's valid and store new configuration ("page data")
      // if token is invalid, clear any existing session and redirect to login
      const client = new FrankieApiClient(process.env.VUE_APP_SERVER_API)
      const auth = client.getClient('authentication')

      let newLoginData: LoginResponse | null,
        newToken: string | null,
        newPermissions: string[],
        newIsReactVerticalNavBar = false,
        success = false
      try {
        newLoginData = await auth.checkTokenValidity(queryToken)
        ///// NEED TO UPDATE THIS SPELLING IN THE WHOLE PORTAL AND THEN REMOVE THIS WORK AROUND
        ;(newLoginData as any).organization = newLoginData.organisation
        ;(newLoginData as any) = {
          ...newLoginData,
          ...newLoginData.configuration.value(),
        }
        //// IMPORTANT!!!
        newToken = client.frankieToken
        // newRedirect = queryRedirect ?? localStorage.getItem('originalURL');
        // localStorage.removeItem('originalURL');
        newPermissions = newLoginData.permissions
        newIsReactVerticalNavBar =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newLoginData?.features?.verticalNavBar || false
        success = true
      } catch (e) {
        console.error(e)
        console.error(
          'Token passed in query parameter seems to be invalid. Clearing any existing session...',
        )
        newLoginData = null
        newToken = null
        newPermissions = []
        newIsReactVerticalNavBar = false
        success = false
      }
      externalDataStore.setPageData(newLoginData)
      externalDataStore.setPermissions(newPermissions)
      externalDataStore.setIsReactVerticalNavBar(newIsReactVerticalNavBar)
      externalDataStore.setToken(newToken)
      //check if there is an originalURL, if yes, we redirect to that url, otherwise we redirect user to onboarding page
      //originURL is the url that they try to access earlier but being kickout because of not authorized.
      //clean up the originalURL
      if (success) {
        const url = new URL(window.location.href)
        url.searchParams.delete('token')
        return (window.location.href = url.href)
      } else return redirectToLogin()
    } else if (queryEndSession) {
      // if has a end_session command in query param
      // if the content of end_session is the uuid of the current session
      // end session and redirect to login

      return redirectToLogin()
    } else if (storedToken && !isValidPageData(storedPageData)) {
      // if has session token, but no configuration
      // fetch and store configuration
      const client = new FrankieApiClient(process.env.VUE_APP_SERVER_API)
      const auth = client.getClient('authentication')
      let newLoginData: LoginResponse | null
      try {
        newLoginData = await auth.checkTokenValidity(storedToken)
      } catch (e) {
        console.error(e)
        externalDataStore.setPermissions([])
        externalDataStore.setIsReactVerticalNavBar(false)
        externalDataStore.setPageData(null)
        externalDataStore.setToken(null)
        return redirectToLogin()
      }
      ///// NEED TO UPDATE THIS SPELLING IN THE WHOLE PORTAL AND THEN REMOVE THIS WORK AROUND
      ;(newLoginData as any).organization = newLoginData.organisation
      ;(newLoginData as any) = {
        ...newLoginData,
        ...newLoginData.configuration.value(),
      }
      //// IMPORTANT!!!
      externalDataStore.setPageData(newLoginData)
      window.location.reload()
    } else if (!storedToken && !to.meta.isPublicPage) {
      // if there's no stored token and not in public page
      // save current url in storage and redirect to login (both actions are present in "redirectToLogin")
      return redirectToLogin()
    } else if (storedToken && to.name === 'login') {
      // if has stored token and is in login page
      // redirect to stored url or default private page
      const storedUrl = storedRedirect ?? defaultRedirectRoute
      const route = mkAbsoluteUrlRelative(storedUrl)
      externalDataStore.clearStoredUrl()
      return next(route)
    } else {
      return next()
    }
  }
function isValidPageData(obj: any) {
  return Boolean(
    !validate(obj ?? {}, {
      user: { presence: true },
      'user.roles': { presence: true },
      permissions: { presence: true },
    }),
  )
}
