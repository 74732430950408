import mapRouteToUrl from '@/utils/routeToUrlMapper'
// import MultipepResultList from "@/entryFiles/data/multipep_result_list_response.json";

import axios from 'axios'
import config, { axiosConfigForNode } from '@/api/axiosConfig'
export async function fetchMultipepResultList(entityId, options = {}) {
  const url = mapRouteToUrl('ffportal_applicant_aml', { entityId })
  const { ABORT_CONTROL } = Object.assign({}, options)
  return axios
    .get(url, { ...axiosConfigForNode, cancelToken: ABORT_CONTROL.token })
    .then(res => res.data)
}

export async function fetchMultipepResultListDetail(entityId, amlGroupId) {
  const url = mapRouteToUrl('ffportal_applicant_aml_detail', {
    entityId,
    amlGroupId,
  })
  return axios.get(url, axiosConfigForNode).then(res => res.data)
}

export async function updateMultipepMatchStatus(
  entityId,
  checkId,
  comment,
  status,
  groupIds = [],
) {
  const url = mapRouteToUrl('ffportal_entity_aml_bulk_status', {
    entityId,
    checkId,
  })
  return axios
    .put(url, { comment, groupIds, status }, axiosConfigForNode)
    .then(res => res)
}

export async function toggleOngoingAML(flag, entityId) {
  const url = mapRouteToUrl('ffportal_aml_toggle_ongoing', { flag, entityId })
  return axios.put(url, { flag }, axiosConfigForNode).then(res => res)
}
