import getUrl from "@/utils/routeToUrlMapper";
import { checkFetchError } from "@/api/fetchErrorHandler";
import axios from "axios";
import { axiosConfigForNode } from "./axiosConfig";
export async function deleteDocument(documentId, entityId, docType) {
  const url = getUrl("ffportal_document_delete", {
    documentId,
    entityId,
    docType
  });
  return axios.delete(url, axiosConfigForNode);
}
