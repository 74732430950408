<script>
import PortalFrame from "@/frames/portal-frame/PortalFrame";
import IntentFrame from "@/frames/intent-frame/IntentFrame";
import { getRouteSidebarLinks } from "@/utils/routeToUrlMapper";

export default {
  components: {
    PortalFrame,
    IntentFrame
  },
  computed: {
    navigationItems() {
      return getRouteSidebarLinks(this.currentPath);
    }
  }
};
</script>
<template lang="pug">
    v-app( v-if="isVuetifyApp" )
        portal-frame
            intent-frame( drawLine )
                .page-content
                    slot
    portal-frame( v-else )
        intent-frame( drawLine )
            .page-content
                slot
</template>
<style lang="sass" scoped>
.page-content
    height: 100%
    height: calc(100vh - #{$LENGTH__HEADER_HEIGHT})
    display: flex
    flex-direction: column
</style>
