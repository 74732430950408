<template lang="pug">
    text-input( v-if="!separateInputs" v-bind="attrs" v-model="fullName"  @clear="$emit('clear')"  showClearIcon)
    label-wrapper( v-else :label="label" boldLabel)
        text-input( v-for="([placeholder, field]) in SPLIT_FIELDS" :label="placeholder" :validation="!hasIssues(field)" :placeholder="placeholder", :value="value[field]" @input="splitSetter(field, $event)" :qa='`${fieldName}_${field}`')
        //- text-input(placeholder="Other Name", v-bind="getFieldProps('extraData.registered_name_other_given')", v-on="getFieldEvents('extraData.registered_name_other_given')")
        //- text-input(placeholder="Family Name", v-bind="getFieldProps('extraData.registered_name_family')" v-on="getFieldEvents('extraData.registered_name_family')")
</template>
<script>
import SmartInputMixin from "@/components/smart-inputs/SmartInput.mixin";
import TextInput from "@/components/inputs/TextInput";
import LabelWrapper from "@/components/wrappers/LabelMessageWrapper";
import HasIssuesMixin from "@/mixins/validation/HasIssues.mixin";

export default {
  mixins: [SmartInputMixin, HasIssuesMixin],
  constants: {
    SPLIT_FIELDS: [
      ["First Given Name", "given"],
      ["Other Given Name (optional)", "other"],
      ["Family Name", "family"]
    ]
  },
  components: {
    TextInput,
    LabelWrapper
  },
  props: {
    separateInputs: Boolean,
    label: String,
    fieldName: String
  },
  computed: {
    fullName: {
      get() {
        const fullName = this.extractFullNameFromNameFields(this.value);
        return fullName;
      },
      set(fullName) {
        const nameFields = this.extractNameFieldsFromFullName(fullName);

        if (
          nameFields.givenName !== this.value.givenName ||
          nameFields.middleName !== this.value.middleName ||
          nameFields.familyName !== this.value.familyName
        )
          this.emitValue(nameFields);
      }
    }
  },
  methods: {
    splitSetter(field, val) {
      const value = { ...this.value };
      value[field] = val;
      this.$emit("input", value);
    },
    extractNameFieldsFromFullName(fullName) {
      const pieces = fullName
        .split(" ")
        .map(el => el.trim())
        .filter(el => el && el.length > 0);

      let givenName = "",
        familyName = "",
        middleName = "";

      if (pieces.length > 0) givenName = pieces.splice(0, 1)[0];
      if (pieces.length > 0) familyName = pieces.pop();
      if (pieces.length > 0) middleName = pieces.join(" ");

      return {
        givenName,
        middleName,
        familyName
      };
    },
    extractFullNameFromNameFields(nameFields) {
      const { givenName, middleName, familyName } = nameFields;
      return [givenName, middleName, familyName].filter(el => el && el.length > 0).join(" ");
    }
  }
};
</script>
