import Vuex from "vuex";
import applicants from "@/store/applicants";
import applicant, { clearCacheApplicantDetails } from "@/store/applicant";
import PEP, { clearCachePEP } from "@/store/applicantTabs/PEP";
import BUSINESS, { clearOwnershipData } from "@/store/applicantTabs/BUSINESS";
import AUDIT, { clearCacheAUDIT } from "@/store/applicantTabs/AUDIT";
import OCR, { clearCacheBIOMETRY } from "@/store/applicantTabs/OCR";
import FRAUD, { clearCacheFRAUD } from "@/store/applicantTabs/FRAUD";
import system from "@/store/system";

export default function({ pageData }) {
  return new Vuex.Store({
    modules: {
      applicants,
      applicant,
      tabs_PEP: PEP,
      tabs_BUSINESS: BUSINESS,
      tabs_AUDIT: AUDIT,
      tabs_OCR: OCR,
      tabs_FRAUD: FRAUD,
      system: system({ pageData })
    },
    actions: {
      clearCacheInTabs({ commit }) {
        commit(clearCachePEP);
        commit(clearCacheAUDIT);
        commit(clearCacheBIOMETRY);
        commit(clearCacheApplicantDetails);
        commit(clearCacheFRAUD);
        commit(clearOwnershipData);
      }
    }
  });
}
