import { ID_TYPES } from "@/utils/defaultsDocFormResolver";
import { getDocumentCategoryType } from "@/utils/defaultsDocFormResolver";
import DocumentCategoryInput from "@/components/inputs/DocumentCategoryInput";
import { makeRequiredValidator } from "@/utils/validators/basicValidators";

export default {
  props() {
    return {
      label: "Document category",
      placeholder: "Document category",
      shouldDisplay: this.kycMethod === "manual",
      disabled: (() => {
        const idType = this.idType;
        // if it's OTHER, category is NOT disabled == is enabled
        if (idType === ID_TYPES.OTHER) return false;

        // if it already has category defined, it's disabled
        const country = this.value?.country || "";
        const mkycOptions = this.pageData?.mkyc_rules || "";
        const categoryForThisField = getDocumentCategoryType(mkycOptions, { idType, country });

        if (categoryForThisField) return true;

        return false;
      })()
    };
  },
  required() {
    return this.kycMethod === "manual";
  },
  visibility() {
    return this.kycMethod === "manual";
  },
  classes() {
    return "third-quarter";
  },
  component: DocumentCategoryInput
};
