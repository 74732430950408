<template lang="pug">
    .date-input.no-message(:id="id"  :class="{disabled}" )
        label-wrapper( :label="label" :for="id" )
            .picker-wrapper
                v-autocomplete.select-mode(v-if="minimumView==='year' && !notShowDatePicker" :items='years' :color='COLOR__TEXT_GREY' v-model='changeYear' item-text='label' placeholder="YYYY" outline  solo hide-details flat :class='{error: !validation}' :data-qa='qa' :disabled="disabled")
                v-menu.calendar-mode(v-else ref="menu1" v-model="menu1" :close-on-content-click="false"  z-index='10000' transition="scale-transition" offset-y="" max-width="290px" min-width="290px" :disabled="notShowDatePicker || disabled" )
                    template(v-slot:activator="{ on }")
                        v-text-field(:id='id' ref='dateInput' :autocomplete='false' :value='dateFormatted' :class='{error: !validation}' :error='!validation' no-title :placeholder="placeholder || 'DD-MM-YYYY'" v-mask='dateMask' v-on="on" outlined :disabled='disabled' flat  solo loader-height='36' height='36' @change='changeTextInput' :data-qa='qa' :background-color="disabled ? '#F4F5F7' : ''" :color="disabled ? '#9EA4AA' : '#0C1C2B'")
                    v-date-picker( ref='picker' reactive no-title :value="getDateForDatePicker" :type='datePickerType' @change="emitDate"  :allowed-dates='allowedDate' )

</template>
<style lang="sass" scoped>

.date-input
    cursor: inherit !important
    &::v-deep :focus
        outline: none
    .select-mode::v-deep .v-input__slot
        border: 1px solid $COLOR__GREY
    .calendar-mode::v-deep fieldset
        border-width: 1px !important
        border-color: $COLOR__GREY !important
    &.disabled .picker-wrapper
        cursor: not-allowed
.v-text-field--outlined
    &::v-deep fieldset
        border-color: #dadcde
        -webkit-transition-duration: 0s
        transition-duration: 0s !important
        -webkit-transition-timing-function: none !important
        transition-timing-function: none !important
.v-input--is-focused
    border-color: $COLOR__BLUE !important
    &::v-deep fieldset
        border-color: $COLOR__BLUE !important
.picker-wrapper
    background-color: white
    position: relative
    .third-party
        position: absolute
        z-index: 9999
    &::v-deep .v-input
        height: 36px!important
        input
            font-size: 14px !important
            font-weight: 600!important
            line-height: 150%!important
    &::v-deep .v-input__slot
        min-height: auto!important
        height: 36px!important
        margin-bottom: 0 !important
    &::v-deep .v-text-field__details
        display: none !important
.error::v-deep
    .v-input__slot fieldset
        +input-style(error)
        border-width: 2px !important
</style>

<script>
import LabelWrapper from "@/components/wrappers/LabelMessageWrapper";
import ListPickInput from "../inputs/ListPickInput";
import SmartInputMixin from "@/components/smart-inputs/SmartInput.mixin";
import TextInput from "@/components/inputs/TextInput";
import DateInputCalendar from "vuejs-datepicker";
import moment from "moment";
import { DISPLAY_DATE_FORMAT, DISPLAY_MONTH_FORMAT, DISPLAY_YEAR_FORMAT } from "@/constants.js";
import style from "@/styles/config.sass";
import { mask } from "vue-the-mask";
export default {
  constants: {
    COLOR__TEXT_GREY: style.COLOR__TEXT_GREY
  },
  directives: {
    mask
  },
  mixins: [SmartInputMixin],
  label: "DateInput",
  components: {
    ListPickInput,
    LabelWrapper,
    TextInput,
    DateInputCalendar
  },
  props: {
    value: String,
    select: Boolean,
    serialize: Boolean,
    minimumView: String,
    showPastOnly: Boolean,
    notShowDatePicker: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      menu1: false
    };
  },

  computed: {
    dateFormatted() {
      return this.value ? moment(this.value).format(this.customDateFormat) : "";
    },
    years() {
      const currentYear = +moment().format("YYYY");
      const year = [];
      for (let index = currentYear; index >= currentYear - 100; index--) {
        year.push(`${index}`);
      }

      return year;
    },
    changeYear: {
      get() {
        return this.value;
      },
      set(year) {
        this.$emit("input", `${year}`);
      }
    },
    getDate() {
      //Check for the value, if it is empty, return empty string instead of defaul value
      return this.value ? moment(this.value).format(this.customDateFormatRevert) : "";
    },
    getDateForDatePicker() {
      //external library for date picker is not accept empty string
      if (this.getDate === "") return moment().format(this.customDateFormatRevert);
      return this.getDate;
    },
    customDateFormat() {
      let dateFormat;
      switch (this.minimumView) {
        case "month":
          dateFormat = DISPLAY_MONTH_FORMAT;
          break;
        case "year":
          dateFormat = DISPLAY_YEAR_FORMAT;
          break;

        default:
          dateFormat = DISPLAY_DATE_FORMAT;
          break;
      }
      return dateFormat;
    },
    customDateFormatRevert() {
      let dateFormat;
      switch (this.minimumView) {
        case "month":
          dateFormat = "YYYY-MM";
          break;
        case "year":
          dateFormat = "YYYY";
          break;
        default:
          dateFormat = "YYYY-MM-DD";
          break;
      }
      return dateFormat;
    },
    datePickerType() {
      let dateFormat;
      switch (this.minimumView) {
        case "day":
          dateFormat = "date";
          break;
        case "month":
          dateFormat = "month";
          break;

        default:
          dateFormat = "date";
          break;
      }
      return dateFormat;
    },
    dateMask() {
      return this.customDateFormat.replace(/[A-Za-z]/g, "#");
    }
  },
  methods: {
    allowedDate(val) {
      if (!this.showPastOnly) return true;
      const now = moment();
      const selectedDate = moment(val, "YYYY-MM-DD");
      return selectedDate < now;
    },
    emitDate(value) {
      this.menu1 = false;
      this.$emit("input", value);
    },
    changeTextInput(dateString) {
      const dateMoment = moment(dateString, this.customDateFormat, true);
      const isDateInputValid = dateMoment.isValid();
      this.menu1 = false;
      if (isDateInputValid) {
        //do something when it is valid
        const outputDate = dateMoment.format(this.customDateFormatRevert);
        this.emitDate(outputDate);
      } else {
        //Use ref to reset the input field if there is not valid date input
        this.$refs.dateInput.lazyValue = "";
        this.emitDate("");
      }
    }
  },
  watch: {
    minimumView: function(oldValue, newValue) {
      //reset the value when change the view
      if (oldValue !== newValue) this.$emit("input", "");
    },
    value: function(val) {
      //watch for the change of the value from parent, if it not same as format, we emit the correct format of the date to update state
      this.emitDate(this.getDate);
    }
  },
  mounted() {
    //sometime backend return the wrong date format, so if it wrong, we will correct it by modify by using computed getDate
    if (this.value !== this.getDate) this.emitDate(this.getDate);
    //hacky way to turn off the autocomplete vuetify
    if (this.minimumView !== "year") this.$refs.dateInput.$refs.input.autocomplete = "off";
    const relativeXPosition = (() => {
      const { x } = this.$el.getBoundingClientRect();
      const vpWidth = window.innerWidth;

      return x / vpWidth;
    })();
    this.openCalendarToSide = relativeXPosition < 0.5 ? "right" : "left";
  }
};
</script>
