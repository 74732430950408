import idNumberField from "./fields/idNumberField";
import common from "@frankieone/shared";
import mergeBlueprint from "@/utils/mergeBlueprint.js";
const { validator } = common;
let blueprint = {
  fields: {
    idNumber: { ...idNumberField({ label: "ID Number", placeholder: "ID Number" }) },
    country: {
      view() {
        return {
          value: this.country(this.fieldValue),
          title: "Country"
        };
      },
      visibility() {
        return true;
      }
    },
    idSubType: {
      ...idNumberField({ label: "ID Subtype", placeholder: "ID Subtype" }),
      view() {
        return {
          value: this.fieldValue,
          title: "ID Subtype"
        };
      },
      visibility() {
        return true;
      }
    }
  }
};
blueprint = mergeBlueprint("TAX_ID", blueprint);
export { blueprint };
