import { render, staticRenderFns } from "./LabelMessageWrapper.vue?vue&type=template&id=093a898e&scoped=true&lang=pug&"
import script from "./LabelMessageWrapper.vue?vue&type=script&lang=js&"
export * from "./LabelMessageWrapper.vue?vue&type=script&lang=js&"
import style0 from "./LabelMessageWrapper.vue?vue&type=style&index=0&id=093a898e&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "093a898e",
  null
  
)

export default component.exports