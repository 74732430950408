export const auth = localStorage.getItem("token");
const isProd = process.env.NODE_ENV === "production";

export const axiosConfigForNode = {
  maxRedirects: 0,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Referrer-Policy": "origin",
    "X-Frankie-Channel": "portal",
    auth,
    ...(isProd
      ? {
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains"
        }
      : {})
  }
};
