import { Document, getAustralianStateDetails, Applicant } from "@frankieone/shared";
import { DISPLAY_DATE_FORMAT, DISPLAY_MONTH_FORMAT, DISPLAY_YEAR_FORMAT } from "@/constants";
import moment from "moment";
import _ from "lodash";
import countries from "@/data/country";
import { ID_TYPES } from "@/utils/defaultsDocFormResolver";
import { blueprint as passportBlueprint } from "@/components/builders/documentForm/blueprints/passport";
import { blueprint as driversBlueprint } from "@/components/builders/documentForm/blueprints/driversLicense";
import { blueprint as visaBlueprint } from "@/components/builders/documentForm/blueprints/visa";
import { blueprint as medicareBlueprint } from "@/components/builders/documentForm/blueprints/medicare";
import { blueprint as immigrationBlueprint } from "@/components/builders/documentForm/blueprints/immigration";
import { blueprint as birthBlueprint } from "@/components/builders/documentForm/blueprints/birth.js";
import { blueprint as nameChangeBlueprint } from "@/components/builders/documentForm/blueprints/nameChange";
import { blueprint as marriageBlueprint } from "@/components/builders/documentForm/blueprints/marriage";
import { blueprint as vehicleBlueprint } from "@/components/builders/documentForm/blueprints/vehicle";
import { blueprint as citizenshipBlueprint } from "@/components/builders/documentForm/blueprints/citizenship";
import { blueprint as nationalIdBlueprint } from "@/components/builders/documentForm/blueprints/nationalId";
import { blueprint as otherBlueprint } from "@/components/builders/documentForm/blueprints/other";
import { blueprint as taxIdBlueprint } from "@/components/builders/documentForm/blueprints/taxID";
type IDocumentView = {
  title: string;
  value: string | null;
  required?: boolean;
  visibility?: boolean;
};
type IProps = {
  [key: string]: any;
};
type IDocumentBlueprint = {
  fields: {
    [x: string]: {
      component: Vue.Component;
      props: () => IProps;
      view: () => {
        title: string;
        value: string | null;
      };
      width: () => string;
      required?: () => boolean;
      visibility?: () => boolean;
      fieldValue?: string | null;
      value?: Document;
      applicant?: Applicant;
      getFullName?: (a: string, b: string, c: string) => string;
      getFieldValue?: (path: string) => string | null;
      state?: string;
      country?: (codeAlpha3: string) => string;
    };
  };
};

export class DocumentViewTranform {
  document: Document;
  applicant: Applicant;
  constructor(document: Document, applicant?: Applicant) {
    this.document = document;
    this.applicant = applicant;
    this.init();
  }
  init() {
    // Give context to the blueprint
    this.giveContext();
  }
  make(): IDocumentView[] {
    const blueprint = this.blueprint;
    //when blueprint is not found or document is not supported, we just display the default value idNumber
    if (!blueprint) return [{ title: "ID Number", value: this.document.idNumber }];
    let documentViews: IDocumentView[] = [];
    if (blueprint) {
      const fields = blueprint.fields;
      const excludeFields = ["extraData.kyc_document_category"];
      for (const key in fields) {
        if (excludeFields.includes(key)) break;
        const fieldVisibility = fields[key].visibility?.();
        const fieldRequired = fields[key].required?.();
        const view = fields[key].view?.();
        documentViews.push({
          ...view,
          visibility: fieldVisibility,
          required: fieldRequired
        });
      }
    }
    const filterEmptyField = (documentView: IDocumentView): boolean => !(!documentView.title && !documentView.value);
    const filterFieldOptionalWithEmptyValue = (documentView: IDocumentView): boolean =>
      !((!documentView.required && !documentView.value) || !documentView.visibility);
    return documentViews.filter(filterEmptyField).filter(filterFieldOptionalWithEmptyValue);
  }
  giveContext() {
    if (!this.blueprint) return;

    const fields = this.blueprint.fields;
    //Each field in blue print can access to the property we define here
    for (const key in fields) {
      fields[key].value = this.document;
      fields[key].applicant = this.applicant;
      fields[key].fieldValue = this.getValue(key);
      fields[key].getFieldValue = this.getValue.bind(this);
      fields[key].getFullName = this.getFullName;
      fields[key].state = this.stateInFullForAus(this.getValue(key));
      fields[key].country = this.getCountryInFull;
    }
  }
  getValue(path: string) {
    return _.get(this.document, path);
  }
  getCountryInFull(countryCodeAlpha3: string): string {
    return countries.find(country => country.alpha3Code === countryCodeAlpha3)?.name || "";
  }
  getFullName(givenName: string, otherGivenName: string, familyName: string): string {
    return [givenName, otherGivenName, familyName].filter(Boolean).join(" ");
  }
  stateInFullForAus(region: string): string {
    return getAustralianStateDetails(region)?.label || region || "";
  }
  get blueprint(): IDocumentBlueprint | null {
    const idType = this.document.idType;
    let blueprint: IDocumentBlueprint;
    switch (idType) {
      case ID_TYPES.PASSPORT:
        blueprint = passportBlueprint;
        break;
      case ID_TYPES.DRIVERS_LICENCE:
        blueprint = driversBlueprint;
        break;
      case ID_TYPES.VISA:
        blueprint = visaBlueprint;
        break;
      case ID_TYPES.MEDICARE_CARD:
        blueprint = medicareBlueprint;
        break;
      case ID_TYPES.IMMIGRATION:
        blueprint = immigrationBlueprint;
        break;
      case ID_TYPES.BIRTH_CERT:
        blueprint = birthBlueprint;
        break;
      case ID_TYPES.NAME_CHANGE:
        blueprint = nameChangeBlueprint;
        break;
      case ID_TYPES.MARRIAGE_CERT:
        blueprint = marriageBlueprint;
        break;
      case ID_TYPES.VEHICLE_REGISTRATION:
        blueprint = vehicleBlueprint;
        break;
      case ID_TYPES.CITIZENSHIP:
        blueprint = citizenshipBlueprint;
        break;
      case ID_TYPES.NATIONAL_ID:
      case ID_TYPES.BANK_STATEMENT:
        blueprint = nationalIdBlueprint;
        break;
      case ID_TYPES.TAX_ID:
        blueprint = taxIdBlueprint;
        break;
      case ID_TYPES.OTHER:
        blueprint = otherBlueprint;
        break;
    }
    // if(!blueprint) throw "Couldn't find blueprint for this idType"
    return blueprint;
  }
}
export const getMedicareColor = (document: Document): string => {
  const idSubType = document.idSubType;
  switch (idSubType) {
    case "G":
      return "Green";
      break;
    case "B":
      return "Blue";
      break;
    case "Y":
      return "Yellow";
      break;
    default:
      return "";
      break;
  }
};
export const formatDate = (date, formatType): string => {
  if (!date) return "Invalid date";
  return moment(date).format(formatType);
};
