import Model from "./Model";
import mapper, { sorter } from "@/utils/backendAlertMapper";
import sMapper from "@/utils/stateBackendMapper";
/**
 * This class carries all check and check related information, such as status and risk assessment
 */

export default class ApplicantCheck extends Model {
  constructor() {
    super();

    // these two may be the same thing. If they're not, what's the difference?
    // alertList comes in onboardingList as comma separated string
    // amlList comes in applicantDetails as array. <-- this is always preferable
    this.alertList = [];
    // this.amlList = [];
    this.duplicateResolved = false;
    this.confidenceLevel = 0;

    // this.extraData = []; this is kvp, we need to remove this. The only thing I use this for at the moment is risk.level in onboarding|monitoringList
    // this.riskLevel = 0; // this would be the actual calculated risk number
    // this.riskClass = "UNKNOWN"; // this could be the risk label (UNKNOWN, LOW, MEDIUM, HIGH, UNNACEPTABLE...)
    this.risk = {
      level: 0,
      class: "UNKNOWN",
      normalized: null
    };
    this.status = {
      type: "unchecked",
      label: "Unchecked",
      key: "UNCHECKED"
    }; // not always an outcome of a check, as can also be manually defined, but is close enough to be a check property, so better to keep here
    this.checkDate = null;
    // this is the same as "entitiesVerified", a boolean each
    this.personalChecks = {
      name: null,
      dateOfBirth: null,
      addresses: [], // [ { addressId, verified } ]
      phoneNumber: null,
      email: null,
      chineseName: null
    };
  }
}

export function hasKYCIssue(check) {
  function isKYCIssue(issue) {
    if (typeof issue === "string") {
      throw "You need to normalize your alertList before checking for KYC issues";
    }
    const term = issue.term;
    return term === "partial" || term === "404";
  }
  return check?.alertList?.some(isKYCIssue);
}
export const alertMapper = mapper;
export const alertSorter = sorter;
export const statusMapper = sMapper;
