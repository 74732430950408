import axios from "axios";
import { scanSorter, scanDateSorter } from "@/utils/defaultsDocFormResolver";
import routeToURLMapper from "@/utils/routeToUrlMapper";
import { axiosConfigForNode } from "./axiosConfig";
import { mkDataUrl, dataURItoBlob } from "@/utils/readBase64Document";

export async function getDocumentScans(documentId, entityId = null, logOperation = false) {
  const url = logOperation
    ? routeToURLMapper("ffportal_document_get_scans", { documentId, entityId, logOperation })
    : routeToURLMapper("ffportal_document_get_scans", { documentId });

  const { status, data } = await axios.get(url, axiosConfigForNode);
  const documentScans = status !== 204 ? data : { scans: [] };
  return handleScansData(documentScans);
}

export async function getDocumentScan(documentId, scanId, options = {}) {
  options = Object.assign({ side: "", type: "image" }, options);
  const url = routeToURLMapper("ffportal_get_scan", { documentId, scanId, side: options.side, type: options.type });
  return axios.get(url, config).then(res => res.data);
}

export const handleScansData = scans => {
  const sortScans = scans =>
    scans
      ?.splice(0)
      .sort(scanSorter)
      .sort(scanDateSorter) ?? [];
  //data is object of array scan {scans: [{side, scanMine, scanData}]}
  const sidePoints = side => {
    let points = -1;
    switch (side) {
      case "F":
        points = 2;
        break;
      case "B":
        points = 1;
        break;
    }
    return points;
  };
  scans = scans.map(scan => ({
    scanId: scan.scanId,
    mimeType: scan.mimeType,
    file: scan.file,
    side: scan.side || mapScanType(scan.side),
    scanCreated: scan?.scanCreated || "",
    scanName: scan?.scanName || ""
  }));

  scans = sortScans(scans);
  return scans;
};

export function mapScanType(type) {
  if (!type) return null;
  type = type.toLowerCase();
  switch (type) {
    case "video":
      type = "video";
      break;
    case "photo":
    case "image":
    case "img":
      type = "image";
      break;
  }
  return type;
}
export async function openDocumentNewTab(document) {
  const documentId = document.documentId;
  const scans = await getDocumentScans(documentId);
  if (!scans || !scans.length) return;
  //open the first scan
  const scan = scans[0];
  const base64 = mkDataUrl(scan.mimeType, scan.file);
  const blob = dataURItoBlob(base64);
  const temp_url = window.URL.createObjectURL(blob);
  window.open(temp_url, "_blank");
}
