import TextInput from "@/components/inputs/TextInput";
import { getPageData } from "@/utils/pageDataStorage";

export default function(options) {
  const { label, placeholder, removeSpaces, mask, qa, id } = Object.assign(
    {
      removeSpaces: true,
      placeholder: "Document/Card number",
      label: "Document/Card number",
      mask: null,
      qa: "documentNumberField",
      id: "documentId"
    },
    options
  );
  return {
    props() {
      return {
        disabled: this.disabled,
        removeSpaces,
        placeholder,
        label,
        mask,
        qa,
        id
      };
    },
    visibility() {
      const { qldDlCardNumberRequired, vicDlCardNumberRequired } = getPageData()?.features || {};
      const visibleStates = {
        QLD: qldDlCardNumberRequired || false,
        VIC: vicDlCardNumberRequired || false,
        OTHERS: true
      };
      const isVisibleState = visibleStates[this.value.region] ?? visibleStates["OTHERS"];
      return this.value.region && this.value.country === "AUS" && isVisibleState;
    },
    view() {
      return {
        title: label || "Document Number",
        value: this.fieldValue
      };
    },
    required() {
      return this.value.country === "AUS" && !this.disabled;
    },
    component: TextInput
  };
}
