var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{attrs:{"id":"main-top-header"}},[(!_vm.isAnonymous)?_c('nav',{staticClass:"modules navigation"},[_c('div',{staticClass:"logo module center-stuff",on:{"click":_vm.redirectToHome}},[_c('frankie-icon',{attrs:{"name":"frankie-logo","color":"ORIGINAL","size":{ width: 100, height: 16 },"click-area-ratio":1,"click-area-min-size":0,"clickable":""}})],1),_vm._l((_vm.links),function(ref){
var permission = ref.permission;
var link = ref.link;
var slug = ref.slug;
var text = ref.text;
var icon = ref.icon;
var outlined = ref.outlined;
var shaded = ref.shaded;
var classes = ref.classes;
return (_vm.hasPermission(permission))?_c('router-link',{key:slug,staticClass:"module link center-stuff",class:[{active: _vm.isPathActive(link)}, {outline: outlined}, {shaded: shaded}, classes],attrs:{"to":link,"data-qa":("access link " + slug)}},[_c('simple-icon',{staticClass:"icon",attrs:{"icon":icon,"overrideColor":_vm.isPathActive(link) ? (outlined ? _vm.COLORS.WHITE : _vm.COLORS.BLUE) : (outlined ? _vm.COLORS.WHITE : _vm.COLORS.GRAY),"size":"16px","padding":"1px"}}),_c('span',{staticClass:"f-listButton align-center"},[_vm._v(_vm._s(text))])],1):_vm._e()})],2):_vm._e(),(_vm.isAnonymous)?_c('div',{staticClass:"about"},[_c('a',{style:({color: 'black'}),attrs:{"href":"https://www.frankiefinancial.com"}},[_vm._v("About Frankie")])]):_vm._e(),(!_vm.isAnonymous && _vm.hasPermission('ffportal_user_child_view'))?_c('div',{staticClass:"parent-child",class:{ 'child-selected': _vm.selectedChildId }},[(_vm.currentCustomer)?_c('children-tree-select',{directives:[{name:"loading",rawName:"v-loading.small",value:(_vm.isChildIdSelectorBusy),expression:"isChildIdSelectorBusy",modifiers:{"small":true}}],attrs:{"data":_vm.customerChildData,"selectedIds":_vm.selectedIds,"disabled":_vm.isChildIdSelectorBusy},on:{"input":function($event){_vm.selectedIds = $event}}}):_vm._e()],1):_vm._e(),(!_vm.isAnonymous)?_c('div',{staticClass:"user"},[_c('user-menu',{attrs:{"usernameDisplayIcon":_vm.pageData && _vm.pageData.user && _vm.usernameDisplayIcon(_vm.pageData.user.realname)}})],1):_vm._e(),_c('div',{staticClass:"loading",class:{ shownLoadingAnimation: _vm.loading },attrs:{"data-qa":"new-profile-submission-loading"}},[_c('div',{staticClass:"overlay-background"}),_c('div',{staticClass:"frankie-logo"},[_c('img',{staticClass:"logo-animation",attrs:{"src":require("../../assets/svg/f-loading.svg")}}),(_vm.loadingExtraText)?_c('div',{staticClass:"extra-text f-body-bold"},[_vm._v(_vm._s(_vm.loadingExtraText))]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }