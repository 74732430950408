import idNumberField from "./fields/idNumberField";
import { ID_COUNTRY_CODES } from "@/utils/defaultsDocFormResolver.js";
import ListPickInput from "@/components/inputs/ListPickInput";
import TextInput from "@/components/inputs/TextInput";
import common from "@frankieone/shared";
import mergeBlueprint from "@/utils/mergeBlueprint.js";
const { validator } = common;
let blueprint = {
  fields: {
    idNumber: {
      ...idNumberField(),
      props() {
        const { country } = this.value;
        let mask = null;
        let removeSpaces = true;
        if (country === ID_COUNTRY_CODES.THAILAND) {
          mask = "# #### ##### ## #";
          removeSpaces = false;
        }
        if (country === ID_COUNTRY_CODES.INDONESIA) mask = "################";
        const props = {
          removeSpaces,
          placeholder: this.value.idType === "BANK_STATEMENT" ? "Bank Card Number" : "ID Number",
          label: this.value.idType === "BANK_STATEMENT" ? "Bank Card Number" : "ID Number",
          qa: "idNumberField"
        };
        if (mask) props.mask = mask;
        return props;
      },
      classes() {
        if (this.value.country === "MEX") return "full";
        else return "";
      },
      view() {
        return {
          title: this.value.idType === "BANK_STATEMENT" ? "Bank Card Number" : "ID Number",
          value: this.fieldValue
        };
      },
      validator() {
        const fieldValue = this.value.idNumber;
        const length = fieldValue.length;
        const country = this.value.country;

        if (country === "USA") {
          return length === 4 || length === 9;
        } else if (country === "THA") {
          return length === 17;
        } else if (country === "IDN") {
          return length === 16;
        } else {
          return true;
        }
      }
    },
    "extraData.gender": {
      component: ListPickInput,
      id() {
        return "indiaGenderPick";
      },
      props() {
        return {
          placeholder: "Gender",
          label: "Gender",
          options: [
            { value: "M", label: "Male" },
            { value: "F", label: "Female" }
          ],
          qa: "gender"
        };
      },
      visibility() {
        return this.value.idSubType === "EPIC";
      },
      view() {
        return {
          title: "Gender",
          value: this.fieldValue
        };
      }
    },
    "extraData.home_country_fullname": {
      component: TextInput,
      props() {
        return {
          placeholder: "Full Name as Shown on ID",
          label: "Full Name as Shown on ID",
          qa: "chineseFullname"
        };
      },
      visibility() {
        return this.value.country === "CHN";
      },
      view() {
        return {
          title: "Full Name as Shown on ID",
          value: this.fieldValue
        };
      }
    },
    "extraData.paternal_familyname": {
      component: TextInput,
      props() {
        return {
          placeholder: "Paternal Name”",
          label: "Paternal Name",
          qa: "paternalName"
        };
      },
      visibility() {
        return this.value.country === ID_COUNTRY_CODES.MEXICO;
      },
      view() {
        return {
          title: "Paternal Name",
          value: this.fieldValue
        };
      }
    },
    "extraData.maternal_familyname": {
      component: TextInput,
      visibility() {
        return this.value.country === ID_COUNTRY_CODES.MEXICO;
      },
      props() {
        return {
          placeholder: "Maternal Name”",
          label: "Maternal Name",
          qa: "maternalName"
        };
      },
      view() {
        return {
          title: "Maternal Name",
          value: this.fieldValue
        };
      }
    },
    "extraData.laser_code": {
      component: TextInput,
      visibility() {
        return [ID_COUNTRY_CODES.THAILAND].includes(this.value.country);
      },
      props() {
        return {
          placeholder: "Laser Code",
          label: "Laser Code",
          qa: "laserCode",
          mask: "XXX-XXXXXXX-XX"
        };
      },
      view() {
        return {
          title: "Laser Code",
          value: this.fieldValue
        };
      }
    },
    "extraData.national_id": {
      component: TextInput,
      props() {
        return {
          label: "National ID Number",
          placeholder: "National ID",
          qa: "nationalID"
        };
      },
      visibility() {
        return this.value.idType === "BANK_STATEMENT";
      },
      view() {
        return {
          title: "National ID Number",
          value: this.fieldValue
        };
      }
    }
  }
};
blueprint = mergeBlueprint("NATIONAL_ID", blueprint);
export { blueprint };
