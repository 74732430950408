export default function get(path, obj) {
  let value;

  try {
    const splitValue = path.split(".");
    if (splitValue[0] === "extraData") {
      value = obj.extraData[splitValue.slice(1).join(".")];
    } else {
      value = splitValue.reduce((o, i) => o[i], obj);
    }
  } catch {
    value = null;
  }
  return value;
}
