export const URL_STORAGE_KEY = "originalURL";
export function getSavedUrl(): string | null {
  return localStorage.getItem(URL_STORAGE_KEY);
}
export function setSavedUrl(url: URL | string) {
  const theUrlString: string = (url as URL).href ? (url as URL).href : (url as string);
  localStorage.setItem(URL_STORAGE_KEY, theUrlString);
}
export function clearSavedUrl() {
  return localStorage.removeItem(URL_STORAGE_KEY);
}
export function getAndClearSavedUrl(): string | null {
  const url = getSavedUrl();
  clearSavedUrl();
  return url;
}
export function mkRelativeUrlAbsolute(url: string, baseURL: URL): URL {
  let absolute: URL;
  try {
    const parsed = new URL(url);
    // if looks like an absolute url, rewrite base url to make sure it's the same as current portal url
    absolute = new URL(parsed.pathname, baseURL);
  } catch (e) {
    console.log(`Parsing redirect url failed (${url}). Considering it a relative url...`);
    // if failed parsing it's probably a relative url
    absolute = new URL(url, baseURL);
  }
  return absolute;
}
export function mkAbsoluteUrlRelative(url: string): string {
  let relative: string;
  try {
    const parsed = new URL(url);
    // if looks like an absolute url, rewrite base url to make sure it's the same as current portal url
    relative = new URL(parsed.pathname).pathname;
  } catch (e) {
    relative = url;
  }
  return relative;
}
