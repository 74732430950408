<script>
import vClickOutside from "v-click-outside";

export default {
  props: {
    disabled: Boolean
  },
  data: () => ({
    isToggled: false
  }),
  methods: {
    toggle() {
      if (!this.disabled) this.isToggled = !this.isToggled;
    },
    toggled() {},
    onClickOutside() {
      this.isToggled = false;
    },
    blur(event) {
      const relatedTarget = event.relatedTarget;

      if (relatedTarget === null) return;

      this.isToggled = false;
    }
  },
  watch: {
    isToggled() {
      this.toggled();
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
};
</script>
