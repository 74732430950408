import idNumberField from "./fields/idNumberField";
import common from "@frankieone/shared";
import mergeBlueprint from "@/utils/mergeBlueprint.js";
const { validator } = common;
let blueprint = {
  fields: {
    country: {
      visibility() {
        return true;
      },
      required() {
        return true;
      },
      view() {
        return {
          title: "Country of Issue",
          value: this.country(this.fieldValue)
        };
      }
    },
    idNumber: { ...idNumberField() }
  }
};
blueprint = mergeBlueprint("VISA", blueprint);
export { blueprint };
