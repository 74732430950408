import idNumberField from "./fields/idNumberField";
import idExpiryField from "./fields/idExpiryField";
import common from "@frankieone/shared";
import TextInput from "@/components/inputs/TextInput";
import mergeBlueprint from "@/utils/mergeBlueprint.js";
import { DISPLAY_DATE_FORMAT, DISPLAY_MONTH_FORMAT, DISPLAY_YEAR_FORMAT } from "@/constants.js";
import { formatDate } from "@/components/builders/documentForm/DocumentViewTranform.ts";
const { validator } = common;
let blueprint = {
  fields: {
    country: {
      visibility() {
        return true;
      },
      required() {
        return true;
      },
      view() {
        return {
          title: "Country of Issue",
          value: this.country(this.fieldValue)
        };
      }
    },
    idNumber: {
      ...idNumberField({
        label: "Passport Number",
        placeholder: "Passport Number"
      }),
      view() {
        return {
          title: "Passport Number",
          value: this.fieldValue
        };
      },
      visibility() {
        return true;
      }
    },
    idExpiry: {
      ...idExpiryField,
      visibility() {
        return this.value.country === "NZL" && this.fieldValue !== "0001-01-01";
      },
      view() {
        return {
          title: "Date Of Expiry",
          value: formatDate(this.fieldValue, DISPLAY_DATE_FORMAT)
        };
      }
    },
    "extraData.document_number": {
      component: TextInput,
      props() {
        return {
          placeholder: "File number",
          label: "File number",
          qa: "passportFileNumber",
          id: "documentId",
          mask: null
        };
      },
      visibility() {
        return this.value.country === "IND";
      },
      width() {
        return "50%";
      },
      required() {
        return this.value.country === "IND";
      },
      view() {
        return {
          title: "File number",
          value: this.fieldValue,
        };
      },
    },
  }
};

blueprint = mergeBlueprint("PASSPORT", blueprint);
export { blueprint };
