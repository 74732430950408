import moment from "moment";
import { minLength, maxLength } from "vuelidate/lib/validators";
import isEmpty from "@/utils/isEmpty";
import { email } from "vuelidate/lib/validators";

export function makeDateValidator(type) {
  let format = null;

  switch (type) {
    case "year":
      format = "YYYY";
      break;
    case "year-month":
      format = "YYYY-MM";
      break;
    case "complete":
      format = "YYYY-MM-DD";
      break;
  }

  return value => (value ? moment(value, format, true).isValid() : true);
}

export function makeLengthValidator() {
  // parameters below
  const isExact = arguments.length === 1;
  let min = arguments[0],
    max = arguments[1];

  if (isExact) {
    max = min;
  }
  const minValidator = minLength(min),
    maxValidator = maxLength(max);
  return value => {
    value = value || "";
    return value.length >= min && minValidator(value) && maxValidator(value); // vuelidate doesnt validate empty strings
  };
}

export function makeRegexValidator(regex) {
  return value => regex.test(value);
}

export function makeRequiredValidator() {
  return value => !isEmpty(value);
}
export function makeEmailValidator() {
  return value => email(value);
}
