import Model from "./Model";
/**
 * This class carries all information on a specific applicant. One of our client's customers.
 */
export default class Applicant extends Model {
  constructor() {
    super();
    this.entityId = "";
    this.customerReference = ""; // customer (external) id

    this.name = {
      displayName: "", // this is from onboarding list and the three below are from applicant details. We should keep the three separated, if possible
      givenName: "",
      middleName: "",
      familyName: ""
    };

    this.dateOfBirth = ""; // uses ISO 8601
    this.addresses = []; // array of addresses in correct order. addresses[0] is current address and addresses[1] is previous

    this.phoneNumber = {
      documentId: "",
      idNumber: ""
    };
    this.email = {
      documentId: "",
      idNumber: ""
    };

    this.assignee = ""; // assignee
  }
}
export function getFullName(applicant) {
  const { displayName, givenName, middleName, familyName } = applicant.name;
  const fullName = displayName || [givenName, middleName, familyName].filter(Boolean).join(" ");

  return fullName;
}
