import getProfileStatusLabel from '@/api/getProfileStatusLabel'
import docDefaults, {
  scanDateSorter,
  scanSorter,
} from '@/utils/defaultsDocFormResolver'
import routeToURLMapper from '@/utils/routeToUrlMapper'
import shared, {
  Applicant,
  ApplicantSummary,
  BusinessInfo,
  CheckSummary,
  Document,
  Profile,
} from '@frankieone/shared'
import axios, { CancelTokenSource } from 'axios'
import { axiosConfigForNode } from './axiosConfig'

export interface RequestOptions {
  route?: string
  ABORT_CONTROL?: CancelTokenSource
  version?: string
  limit?: number
  appendList?: boolean
  queryString?: string
  [filter: string]: any
}
type ApplicationStage = 'onboarding' | 'monitoring'
export async function getApplicantsList(
  applicationStage: ApplicationStage,
  options: RequestOptions,
) {
  const {
    ABORT_CONTROL,
    route = 'ffportal_applicants_list',
    ...query
  } = {
    ...options,
  }
  //
  const url = routeToURLMapper(route, {
    ...query,
    stage: applicationStage,
    limit: 1, // hard coding limit until core supports this
  })
  return await axios
    .get(url, { ...axiosConfigForNode, cancelToken: ABORT_CONTROL?.token })
    .then(response => {
      const responseData = response.data
      const resultList = responseData.applicants

      const nextPageMarker = responseData.nextPageMarker
      const transformedResults = resultList.map(result =>
        ApplicantSummary.fromJSON(result),
      )
      return {
        ...response,
        nextPageMarker,
        data: transformedResults,
      }
    })
}

export async function getApplicantDetails(entityId, options: RequestOptions) {
  const ABORT_CONTROL = options?.ABORT_CONTROL || { token: undefined }
  const url = routeToURLMapper('ffportal_applicant_detail.function', {
    entityId,
  })
  return axios
    .get(url, { ...axiosConfigForNode, cancelToken: ABORT_CONTROL.token })
    .then(res => {
      return handleApplicantDetailsData(res.data)
    })
}
export async function createNewApplicant(data, type) {
  const isSaveOnly = type === 'saveData'
  const url = routeToURLMapper('ffportal_applicant_new_submit', {
    saveOnly: isSaveOnly,
  })
  return axios.post(url, data, axiosConfigForNode).then(res => res.data)
}
export async function updateApplicant(
  entityId,
  data,
  type,
  options: RequestOptions = { version: 'v1' },
  noNameDobValidation?: boolean,
  noInvaldiateChecks?: boolean,
) {
  const isSaveOnly = type === 'saveData'
  const skipNameDobValidation = noNameDobValidation == true
  const noInvalidate = noInvaldiateChecks == true
  const url = routeToURLMapper('ffportal_applicant_midv_update', {
    entityId,
    version: options.version,
    saveOnly: isSaveOnly,
    noNameDobValidation: skipNameDobValidation,
    noInvalidate: noInvalidate,
  })
  return axios.put(url, data, axiosConfigForNode).then(res => res.data)
}

export type ApplicantData = {
  applicantDetails: Applicant
  businessInfo: BusinessInfo
  checkSummary: CheckSummary
  documents: Document[]
  kycMethod: Profile['kycMethod']
  profile: Profile
  individualKycReports: Document[]
}
export const handleApplicantDetailsData = (
  entityData: ApplicantDetailsPayload,
): ApplicantData => {
  const {
    applicantDetails: ad,
    documents: d,
    checkSummary: cs,
    businessInfo: bi,
  } = entityData
  const applicantDetails = Applicant.fromJSON(ad)

  const businessInfo = bi && BusinessInfo.fromJSON(bi)
  const profile = applicantDetails.profile
  applicantDetails.profile = (applicantDetails as any).profile?.profileType
  const addresses = applicantDetails.addresses

  //first if no residential1, convert the first residential to residential1
  const isAddressesHaveRESIDENTIAL1 = addresses.some(
    add => add.addressType === 'RESIDENTIAL1',
  )

  const residentialIndex = addresses.findIndex(
    a => a.addressType === 'RESIDENTIAL',
  )
  if (!isAddressesHaveRESIDENTIAL1 && residentialIndex >= 0) {
    addresses[residentialIndex].addressType = 'RESIDENTIAL1'
  }

  //second if no residential1, take first null and make it residential1
  const residentialTypes = ['RESIDENTIAL1']
  const isAddressResidential = a => residentialTypes.includes(a.addressType)
  const addressesWithNullType = addresses.filter(a => !a.addressType)
  const hasResidential = addresses.some(isAddressResidential)
  if (!hasResidential && addressesWithNullType.length > 0) {
    const residential = addressesWithNullType[0]
    residential.addressType = 'RESIDENTIAL1'
  }

  //handle suburb being passed through API, needs to be town if town not provided
  for (let address of addresses) {
    if (address.country === 'AUS' && !address.town) {
      address.town = address.suburb
      address.suburb = null
    }
  }

  const kycMethod = cs.kycMethod

  const getScans = dc =>
    dc.docScan?.splice(0).sort(scanSorter).sort(scanDateSorter) ?? []

  const documentDefaultsMerger = dc => {
    const defaultData = docDefaults(dc.idType)
    dc.extraData = { ...defaultData.extraData, ...dc.extraData } //symfony required all the field with null value
    const document = new Document(dc)
    const scans = getScans(dc)
    ;(document as any).files = scans //need to remove files later, now we use scan
    document.scans = scans //need to remove files later, now we use scan
    return document
  }

  const documents = (function (d) {
    // Don't include KYC report documents
    const isNotKYCReport = d =>
      !(d.idType === 'REPORT' && d.idSubType === 'kyc-report')
    const documents = d.filter(isNotKYCReport).map(documentDefaultsMerger)
    return documents
  })(d || [])

  const individualKycReports = (function (d) {
    // Don't include documents that are NOT kyc reports
    const isKYCReport = d =>
      d.idType === 'REPORT' && d.idSubType === 'kyc-report'
    const reports = d.filter(isKYCReport).map(documentDefaultsMerger)
    return reports
  })(d || [])

  return {
    applicantDetails,
    businessInfo,
    documents,
    checkSummary: CheckSummary.fromJSON(cs),
    kycMethod,
    profile,
    individualKycReports,
  }
}
type IOptions = {
  forceCheck?: boolean
  uploadDocs?: boolean
  noInvalidate?: boolean
  [x: string]: any
}
export async function triggerChecks(entityId, options?: IOptions) {
  const { forceCheck, uploadDocs, noInvalidate } = {
    forceCheck: false,
    uploadDocs: false,
    noInvalidate: false,
    ...options,
  }
  const endpointUrl = routeToURLMapper(
    'ffportal_applicant_check_summary.function',
    { entityId },
  )
  return axios.post(
    endpointUrl,
    {},
    { ...axiosConfigForNode, params: { forceCheck, uploadDocs, noInvalidate } },
  )
}

export async function triggerOverrideChecks(entityId, checkField) {
  const endpointUrl = routeToURLMapper(
    'ffportal_applicant_override_check_summary.function',
    { entityId, checkField },
  )
  return axios.post(endpointUrl, {}, axiosConfigForNode)
}

export async function downloadCSV(applicationStage, filters, ABORT_CONTROL) {
  delete filters.nextPageMarker
  const heads = [
    'entity_name',
    'customer_reference',
    'entity_id',
    'profile_status',
    'create_at',
    'last_update',
    'risk_level',
    'issues',
    'assign_to',
  ]
  let accumulateStringForCSV = `data:text/csv;charset=utf-8,${heads.join(',')}`
  async function getApplicantChunk(nextPageMarker?: string) {
    const data = Object.assign(
      {},
      await getApplicantsList(applicationStage, {
        ...filters,
        nextPageMarker,
        ABORT_CONTROL,
      }),
    )
    const listApplicants = data.data
    const listApplicantsCSV = listApplicants.map(applicant =>
      csvTransformer(applicant),
    )
    accumulateStringForCSV = accumulateStringForCSV + listApplicantsCSV.join('')
    //trigger recusive function to loop through and get all the entity whenever it has nextPageMarker
    if (data.nextPageMarker) await getApplicantChunk(data.nextPageMarker)
  }
  await getApplicantChunk()
  generateCSVFromString(`${applicationStage}_report`, accumulateStringForCSV)
}
function stringifyVariable(source) {
  if (!source) return ''
  return `"${escapeQuote(source)}"`
}
function escapeQuote(string) {
  return string.replace(/"/gi, '\\"').replace(/'/gi, "\\'")
}

export function csvTransformer(applicantSummary) {
  const extraData = applicantSummary.extraData
  const entity_name = stringifyVariable(applicantSummary.entityName)
  const customerReference = stringifyVariable(
    applicantSummary.customerReference,
  )
  const entity_id = stringifyVariable(applicantSummary.entityId)
  const profile_status =
    stringifyVariable(
      getProfileStatusLabel(applicantSummary?.applicantStatus?.type),
    ) || ''
  const create_at =
    stringifyVariable(extraData?.findKvpValue('entity.created')) || ''
  const last_update =
    stringifyVariable(extraData?.findKvpValue('entity.updated')) || ''
  const risk_level = stringifyVariable(applicantSummary?.riskLevel?.label) || ''
  const issues =
    stringifyVariable(applicantSummary.alertList.map(i => i.term).join('|')) ||
    ''
  const assign_to = stringifyVariable(applicantSummary.assignee) || ''
  return (
    '\n' +
    [
      entity_name,
      customerReference,
      entity_id,
      profile_status,
      create_at,
      last_update,
      risk_level,
      issues,
      assign_to,
    ].join(',')
  )
}

function generateCSVFromString(fileName, data) {
  const link = document.createElement('a')
  link.href = encodeURI(data)
  link.download = `${fileName}.csv`
  link.click()
}
