<template lang="pug">
    .span.has-tooltip( :data-icon-name="icon" @click="disabled ? '' : $emit('click')" :class="[ type, { round, clickable, hasFrankieIcon } ]" :style="{ backgroundColor: finalBGColor, padding, width: size, height: size, transform: rotate&&`rotateZ(${rotate}deg)` }" v-bind="extraProps"  @mouseover="isHover = true" @mouseleave="isHover = false")
        frankie-icon(v-if="hasFrankieIcon" :name="frankieIcon" :size="frankieIconSize")
        div(v-else :style='{width: "100%", height: "100%"}')
            span.tooltip( v-if="!disabled && tooltipText" v-html="tooltipText" :class="[`open-to-${tooltipDirection}`]" :style="{ backgroundColor: finalTooltipBGColor, color: 'white', borderColor: finalTooltipBGColor  }" )
            slot
                svgicon( v-if="loaded" :name="icon" :color="finalColor" :width="svgSize" :height="svgSize" :original="useOriginalColors" )
</template>
<style lang="sass" scoped>
.span
  display: inline-block
  padding: 5px

  &.round
    border-radius: 100%

  &.clickable
      cursor: pointer
  &::v-deep svg
    display: block
    path
      opacity: 1
.hasFrankieIcon
    display: flex
    height: auto!important
    width: auto!important
</style>

<script>
import WidgetColorMappings from "@/components/widgets/WidgetColorMappings";
import { getHorizontalPositionOfViewport } from "@/utils/uiHelpers/screenPosition";
export default {
  mixins: [WidgetColorMappings],
  props: {
    disabled: Boolean,
    icon: {
      validator: icon => typeof icon === "string" || icon === null,
      required: true
    },
    clickable: Boolean,
    padding: {
      type: String,
      default: "5px"
    },
    overrideColor: String,
    overrideBGColor: String,
    size: {
      type: String,
      default: "16px"
    },
    round: {
      type: Boolean,
      default: false
    },
    tooltipBGColor: {
      type: String
    },
    hoverColor: String,
    rotate: {
      default: 0,
      type: Number
    },
    tooltipTop: Boolean,
    overrideTooltipDirection: {
      type: String,
      validator: v => ["top", "bottom", "left", "right"].includes(v),
      default: "right"
    }
  },
  data: () => ({
    loaded: false,
    tooltipDirection: "left",
    isHover: false
  }),
  computed: {
    hasFrankieIcon() {
      const frankiePrefix = "frankie:";
      return this.icon?.includes(frankiePrefix);
    },
    frankieIcon() {
      if (this.hasFrankieIcon) return this.icon.split(":")[1];
      else return this.icon;
    },
    frankieIconSize() {
      if (this.hasFrankieIcon) return +this.size.match(/\d./)[0];
      else return this.size;
    },
    useOriginalColors() {
      return this.overrideColor === "ORIGINAL";
    },
    listeners() {
      const { click, ...listeners } = this.$listeners;
      return listeners;
    },
    finalTooltipBGColor() {
      return this.tooltipBGColor || this.backgroundColor || this.overrideBGColor;
    },
    finalColor() {
      if (this.disabled) return this.$style.COLOR__GREY;
      if (this.isHover && this.hoverColor) return this.hoverColor;
      if (this.useOriginalColors) return null;
      const override = this.overrideColor && this.getColor(this.overrideColor);

      return override || this.color;
    },
    finalBGColor() {
      // const override = this.overrideBGColor && this.getColor(this.overrideBGColor);
      return this.overrideBGColor || this.backgroundColor;
    },
    svgSize() {
      return "100%"; //`calc(${this.size} - 2*${this.padding})`;
    }
  },

  watch: {
    icon: {
      immediate: true,
      handler: async function() {
        if (this.icon) {
          this.loaded = false;
          await import(/* webpackChunkName: "[request]" */ `@/components/icons/${this.icon}.js`);
          this.loaded = true;
        }
      }
    }
  },
  mounted() {
    const showTop = this.tooltipTop ? "top" : this.overrideTooltipDirection;
    this.tooltipDirection = getHorizontalPositionOfViewport(this.$el) < 0.5 ? showTop : "left";
  }
};
</script>
