import idNumber from "./fields/idNumberField";
import region from "./fields/regionField";
import TextInput from "@/components/inputs/TextInput";
import { DISPLAY_DATE_FORMAT, DISPLAY_MONTH_FORMAT, DISPLAY_YEAR_FORMAT } from "@/constants.js";
import dateField from "./fields/dateField";
import fullNameField from "./fields/fullNameField";
import mergeBlueprint from "@/utils/mergeBlueprint.js";
import { formatDate } from "@/components/builders/documentForm/DocumentViewTranform.ts";
const group1 = ["ACT"]; //idNumber, fullName, certificate_number(optional), certificate_date_of_print(date)
const group2 = ["NSW", "VIC", "WA"]; //idNumber, certificate_date_of_registration(year)
const group3 = ["QLD"]; //idNumber, certificate_date_of_registration(date)
const group4 = ["NT"]; //idNumber, certificate_date_of_print(date), certificate_number, fullName
const group5 = ["SA"]; //idNumber, certificate_date_of_print(Optional -> date), certificate_number(optional), fullName
const group6 = ["TAS"]; //idNumber(optional),  fullName, certificate_date_of_registration(date)

const stateNotToShowNameField = [...group2, ...group3];
const stateToShowReg = [...group1, ...group4, ...group5];
import common from "@frankieone/shared";
const { validator } = common;

let blueprint = {
  fields: {
    region: {
      ...region,
      props() {
        const inherited = region.props.bind(this)();
        return {
          ...inherited,
          placeholder: "Select state or territory",
          label: "State or territory of registration",
          qa: "region"
        };
      },
      visibility() {
        return true;
      },
      view() {
        return {
          title: "Registration State",
          value: this.fieldValue
        };
      }
    },
    idNumber: {
      ...idNumber({ label: "Registration Number", placeholder: "Registration Number" }),
      visibility() {
        return true;
      },
      view() {
        return {
          title: "Registration Number",
          value: this.fieldValue
        };
      }
    },

    ...fullNameField(),
    "full-name": {
      ...fullNameField()["full-name"],
      visibility() {
        return !stateNotToShowNameField.includes(this.value.region) && this.value.country === "AUS";
      },
      view() {
        const givenName = this.getFieldValue("extraData.registered_name_first_given");
        const otherGivenName = this.getFieldValue("extraData.registered_name_other_given");
        const familyName = this.getFieldValue("extraData.registered_name_family");
        return {
          title: "Registered Name",
          value: this.getFullName(givenName, otherGivenName, familyName)
        };
      }
    },
    "extraData.registered_name_first_given": {
      ...fullNameField()["extraData.registered_name_first_given"],
      visibility() {
        return !stateNotToShowNameField.includes(this.value.region) && this.value.country === "AUS";
      }
    },
    "extraData.registered_name_family": {
      ...fullNameField()["extraData.registered_name_family"],
      visibility() {
        return !stateNotToShowNameField.includes(this.value.region) && this.value.country === "AUS";
      }
    },
    "extraData.certificate_date_of_registration": {
      ...dateField,
      props() {
        const inherited = dateField.props.bind(this)();
        const isDisplayYear = group2.includes(this.value.region);
        const minimumView = isDisplayYear ? "year" : "day";
        const placeholder = isDisplayYear ? DISPLAY_YEAR_FORMAT : DISPLAY_DATE_FORMAT;
        return {
          ...inherited,
          label: "Registration Date",
          minimumView,
          placeholder,
          qa: "dateOfReg"
        };
      },

      visibility() {
        return [...group2, ...group3, ...group6].includes(this.value.region) && this.value.country === "AUS";
      },
      view() {
        const isDisplayYear = group2.includes(this.value.region);
        const dateFormat = isDisplayYear ? DISPLAY_YEAR_FORMAT : DISPLAY_DATE_FORMAT;
        return {
          title: "Date of Registration",
          value: formatDate(this.fieldValue, dateFormat)
        };
      }
    },
    "extraData.certificate_number": {
      component: TextInput,
      props() {
        return {
          placeholder: "Certificate Number",
          label: "Certificate Number",
          qa: "certificateNumber"
        };
      },
      visibility() {
        return stateToShowReg.includes(this.value.region) && this.value.country === "AUS";
      },
      view() {
        return {
          title: "Certificate Number",
          value: this.fieldValue
        };
      }
    },
    "extraData.certificate_date_of_print": {
      ...dateField,
      visibility() {
        return stateToShowReg.includes(this.value.region) && this.value.country === "AUS";
      },
      view() {
        return {
          title: "Date of Print",
          value: formatDate(this.fieldValue, DISPLAY_DATE_FORMAT)
        };
      }
    }
  }
};

blueprint = mergeBlueprint("NAME_CHANGE", blueprint);
export { blueprint };
