import { axiosConfigForNode } from "@/api/axiosConfig";
import normalizeItemsArray from "@/utils/apiHelpers/normalizeItemsArray";
import mapRouteToUrl from "@/utils/routeToUrlMapper";
import axios from "axios";
import {
  hasFacialDuplicatesFeature,
  hasFacialDuplicatesReportFetchPermission
} from "@/components/module/applicant-details/biometrics/facial-duplicates/FacialDuplicatesHelper";

// override document report
export async function overrideApplicantOcrOutcome(entityId, data) {
  const url = mapRouteToUrl("ffportal_applicant_ocr_report_manual_override.function", { entityId });

  return axios.put(url, data, axiosConfigForNode).then(res => {
    if (res.status === 204) return null;
    return res.data;
  });
}
export async function overrideDocumentDetailsOutcome(entityId, data) {
  const url = mapRouteToUrl("ffportal_applicant_ocr_document_data_manual_override.function", { entityId });

  return axios.put(url, data, axiosConfigForNode).then(res => {
    if (res.status === 204) return null;
    return res.data;
  });
}

export async function overrideIDVDetailsOutcome(entityId, data) {
  const url = mapRouteToUrl("ffportal_applicant_idv_result_manual_override.function", { entityId });

  return axios.put(url, data, axiosConfigForNode).then(res => {
    if (res.status === 204) return null;
    return res.data;
  });
}

export async function retriggerIDV(entityId, data) {
  const url = mapRouteToUrl("ffportal_applicant_ocr_retrigger_idv", { entityId });

  return axios.post(url, data, axiosConfigForNode).then(res => {
    return res.data;
  });
}

export async function refreshIDVResults(entityId, data) {
  const url = mapRouteToUrl("ffportal_applicant_ocr_refresh_idv", { entityId });

  return axios.post(url, data, axiosConfigForNode).then(res => {
    return res.data;
  });
}

export async function overrideApplicantBiometryOutcome(entityId, data) {
  const url = mapRouteToUrl("ffportal_applicant_biometry_report_manual_override.function", { entityId });
  return axios.put(url, data, axiosConfigForNode).then(res => res.data);
}

export async function getApplicantBiometryReport(entityId, options = {}) {
  const url = mapRouteToUrl("ffportal_applicant_biometry_report.function", { entityId });
  const { ABORT_CONTROL } = Object.assign({}, options);
  const response = await axios.get(url, { ...axiosConfigForNode, cancelToken: ABORT_CONTROL.token });
  let applicantDetails,
    facialDuplicatesDetails = null;
  if (response.status === 204) return null;
  //biometry items
  const idvOverrideResult = response?.data?.idvOverrideResult;
  const facialDuplicateResult = response?.data?.facialDuplicateResult;

  if (hasFacialDuplicatesFeature() && hasFacialDuplicatesReportFetchPermission()) {
    try {
      const { data } = await getApplicantFacialDuplicates({
        entityId: entityId,
        options: ABORT_CONTROL
      });
      applicantDetails = data?.applicantDetails;
      facialDuplicatesDetails = data?.facialDuplicatesDetails?.filter(data => data?.portraitData.length > 0);
    } catch (e) {
      applicantDetails = null;
      facialDuplicatesDetails = null;
    }
  }

  const biometry = response?.data?.biometry.map(item => {
    item.items = normalizeItemsArray(item.items);
    item.confidence_score = parseFloat(item.confidence_score);
    if (isNaN(item.confidence_score)) {
      item.confidence_score = null;
    }
    item.items.map(x => {
      if (x.key === "report.face") {
        x.items = [];
      }
    });
    return item;
  });
  //ocr report
  const documentDetails = response?.data?.documentDetails.map(document => {
    document.checkResultItems = normalizeItemsArray(document.checkResultItems);
    return { ...document, checkResultItems: document.checkResultItems };
  });

  return {
    biometry,
    documentDetails,
    idvOverrideResult,
    facialDuplicateResult,
    applicantDetails,
    facialDuplicatesDetails
  };
}

export const getApplicantFacialDuplicates = async ({ entityId, options }) => {
  const url = mapRouteToUrl("ffportal_applicant_fetch_facial_duplicates_report.function", { entityId });
  return await axios.get(url, { ...axiosConfigForNode, cancelToken: options?.token });
};

export const updateApplicantFacialDuplicates = async ({ entityId, data }) => {
  const url = mapRouteToUrl("ffportal_applicant_fetch_facial_duplicates_report.function", { entityId });
  return await axios.post(url, data, axiosConfigForNode);
};
