<template lang="pug">
    filter-list-pick-input.national-list-pick(
        :attach="$el || attach"
        :label='label'
        :value="selectedOption" @input="emitValue"
        :disabled='disabled'
        :options="nationalityList"
        :qa='qa'
        :menuProps="{zIndex: 10}"
        @update:filter="filterText = $event" )
</template>
<style lang="sass" scoped>
.national-list-pick
    position: relative


.wrapper
    &::v-deep .label-container
        padding-bottom: 6px
</style>

<script>
import SmartInputMixin from "@/components/smart-inputs/SmartInput.mixin";
import FilterListPickInput from "@/components/inputs/FilterListPickInput";
import av from "@/api/AddressValidator";

export default {
  mixins: [SmartInputMixin],
  components: {
    FilterListPickInput
  },
  props: {
    overrideList: Array,
    id: String,
    qa: String,
    returnSingleValue: Boolean,
    attach: [String, HTMLDivElement]
  },
  data: () => ({
    nationalityFullList: [],
    filterText: ""
  }),
  methods: {
    emitValue(value) {
      if (this.returnSingleValue) {
        this.$emit("input", value.value);
        return;
      }
      this.$emit("input", value);
    }
  },
  computed: {
    selectedOption() {
      const option = this.nationalityList.find(opt => opt.value === this.value.value) || {
        value: this.value.value,
        label: this.value.value
      };
      return option;
    },
    nationalityList() {
      if (!this.overrideList) return this.nationalityFullList;
      else return this.nationalityFullList.filter(el => this.overrideList.find(source => el.value === source));
    }
  },
  created() {
    this.nationalityFullList = av.fetchCountryList();
  }
  // mounted() {
  //     if (this.overrideList) {
  //         this.nationalityFullList = this.nationalityFullList.filter(el =>
  //             this.overrideList.find(source => el.value === source)
  //         );
  //     }

  // if (this.selectedOption) {
  //     this.$refs.picker.selected(this.selectedOption);
  // }
};
</script>
