// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".profile-form-title{height:20px;margin-top:16px;margin-bottom:30px}.f-input-space{margin-bottom:24px;padding-right:20px}.f-headline1{font-size:20px}.f-headline1,.f-headline2{font-weight:500;line-height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;font-weight:700}.f-headline2{font-size:18px}.f-headline3{font-weight:500;font-weight:600}.f-headline3,.f-headline3-bold{line-height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;font-size:16px}.f-headline3-bold{font-weight:500;font-weight:700}.f-listButton{font-weight:500;font-weight:600}.f-body,.f-listButton{line-height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;font-size:14px}.f-body{font-weight:500;line-height:150%}.f-body-bold{font-weight:500;line-height:100%;font-size:14px;font-weight:700;line-height:140%}.f-body-bold,.f-label{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end}.f-label{font-size:13px}.f-label,.f-label-xl{line-height:100%;font-weight:500;color:rgba(12,28,43,.6)}.f-label-xl{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;font-size:14px}.line-height__normalize{line-height:normal!important}.f-break-line{height:1px;width:100%;background-color:#e1e3e6;margin-bottom:17px;margin-top:14px;display:inline-block}", ""]);
// Exports
exports.locals = {
	"COLOR__LIGHT_BLUE": "#b3cefd",
	"COLOR__EXTRA_LIGHT_BLUE": "#d9e6fe",
	"COLOR__MID_GREY": "#e1e3e6",
	"COLOR__DARK_NAVY_80": "#3d4955",
	"COLOR__DARK_NAVY_40": "#9ea4aa",
	"COLOR__BLUE": "#297ee1",
	"COLOR__LIGHT_YELLOW": "#fff2df",
	"COLOR__EXTRA_LIGHT_RED": "#ffeae9",
	"COLOR_DARK_RED": "#b21107",
	"COLOR__LIGHT_GREEN": "#b1e7d2",
	"COLOR__LIGHT_REDV2": "#ffd6d3",
	"COLOR__F_LIGHT_YELLOW": "#ffeccf",
	"COLOR__MID_GREY_80": "#d7d9dd",
	"COLOR__CORE_MONO_20": "#e9ecef",
	"COLOR__CORE_MONO_50": "#adb5bd",
	"COLOR__CORE_MONO_70": "#6c757d",
	"COLOR__CORE_MONO_80": "#495057",
	"COLOR__CORE_MONO_90": "#343a40",
	"COLOR__CORE_MONO_WHITE": "#fff",
	"COLOR__CORE_PRIMARY_500": "#4284fb",
	"COLOR__CORE_SECONDARY_900": "#0c1c2b",
	"COLOR__DARK_NAVY": "#0c1c2b",
	"COLOR__MID_LIGHT_GREY": "#cdd0d5",
	"COLOR__PRIMARY_YELLOW": "#ffcf87",
	"COLOR__DARK_NAVY_60": "#6d7780",
	"COLOR__PURPLE": "#9977fc",
	"COLOR__BACKGROUND_GREY": "#f4f5f7",
	"COLOR__BACKGROUND_LIGHTGREY": "#f3f4f6",
	"COLOR__RED": "#ff6157",
	"COLOR__NAVY": "#9ea4aa",
	"COLOR__EXTRA_LIGHT_GREEN": "#d8f3e8",
	"COLOR__GREEN": "#3cc28e",
	"COLOR__LIGHT_RED": "#fff4f4",
	"COLOR__BLUE_SKY": "#509cdc",
	"COLOR__BLUE_LIGHT": "#17b0fa",
	"COLOR__BLUE_LIGHTEST": "#e8f0ff",
	"COLOR_PRIMARY_BLUE": "#2b67f9",
	"COLOR_PRIMARY_BLUE_900": "#1d54f8",
	"COLOR_PRIMARY_BLUE_200": "#a1c2fd",
	"COLOR__VIOLET": "#3611f8",
	"COLOR__SHADOW": "rgba(0,0,0,.1294117647)",
	"COLOR__SHADOW_1": "rgba(0,0,0,.0509803922)",
	"COLOR__SHADOW_2": "rgba(0,0,0,.1019607843)",
	"COLOR__SHADOW_3": "rgba(0,0,0,.0784313725)",
	"COLOR__CLEAR": "#76cc19",
	"COLOR__WHITE": "#fff",
	"COLOR__BEIGE": "#f8f8f8",
	"COLOR__FONT": "#464457",
	"COLOR__DARK": "#2e3442",
	"COLOR__LIGHTERGREY": "#f9fcff",
	"COLOR__LIGHTPINK": "#fcbec9",
	"COLOR__LIGHTGREY": "#eaebf1",
	"COLOR__BORDER_GREY": "#edeff1",
	"COLOR__GREY": "#dadcde",
	"COLOR__GREY_80": "#374151",
	"COLOR__GREY_60": "#6b7280",
	"COLOR__GREY_50": "#9ca3af",
	"COLOR__GREY_30": "#e5e7eb",
	"COLOR__DARK_GREY": "#999da0",
	"COLOR__BLACK": "#000",
	"COLOR__TEXT_GREY": "#8495a0",
	"COLOR__TOP_MENU_GREY": "#757a91",
	"COLOR__TEXT": "#2e3442",
	"COLOR__ACCENT": "#ff6157",
	"COLOR__OK": "#7bc700",
	"COLOR__MANGO": "#ff9527",
	"COLOR__GREEN_APPLE": "#089b32",
	"COLOR__GREEN_LIGHT": "#ebffd6",
	"COLOR__ORANGE": "#ff9f27",
	"COLOR__ORANGE_LIGHT": "#ffe4bd",
	"COLOR__BRIGHTACCENT": "rgba(247,93,119,.1)",
	"COLOR__LIGHTACCENT": "#fee6e6",
	"COLOR__BODY": "#2e3442",
	"COLOR__ALERT": "#f94458",
	"COLOR__ERROR": "#d86757",
	"COLOR_BLUE": "#1a7ff9",
	"COLOR__LIGHTBLUE": "#f0f7ff",
	"COLOR_INFO_LIGHT": "#fffbd4",
	"LENGTH__HEADER_HEIGHT": "60px",
	"FUNC__BOUNCE": "cubic-bezier(.68,-.55,.265,1.55)",
	"COLOR__STATUS_ATTENTION": "#f78704",
	"COLOR__STATUS_POSITIVE_GREEN": "#50990c",
	"COLOR__STATUS_POSITIVE_RED": "#ff3535",
	"COLOR__F_YELLOW": "#fb0",
	"COLOR__LIGHT_MID_GREY": "#f0f1f3",
	"COLOR_TOOLTIP_GREY": "#1f2a37",
	"LENGTH__SM_PADDING": "12px",
	"LENGTH__PADDING": "24px",
	"LENGTH__LG_PADDING": "48px",
	"LENGTH__TITLE_SIZE": "20px",
	"LENGTH__HEADING_SIZE": "18px",
	"LENGTH__LABEL_SIZE": "18px",
	"LENGTH__SM_FONT_SIZE": "14px",
	"LENGTH__FONT_SIZE": "14px",
	"TIME__SHORT_TRANSITION": ".4s"
};
module.exports = exports;
