import DateInput from "@/components/smart-inputs/DateInput";

export default {
  props() {
    return {
      placeholder: "Number",
      label: "Date Of Print",
      mask: null,
      removeSpaces: true
    };
  },
  component: DateInput
};
