const PAGE_DATA_KEY = "pageData";
export function getPageData() {
  const data = localStorage.getItem(PAGE_DATA_KEY);

  if (!data) return null;
  const parsed = JSON.parse(data);

  return parsed;
}

export function setPageData(pageData: unknown) {
  if (pageData === null) return localStorage.removeItem(PAGE_DATA_KEY);
  const data = JSON.stringify(pageData);
  localStorage.setItem(PAGE_DATA_KEY, data);
}
export function getToken() {
  const data = localStorage.getItem("token");

  if (!data) return null;

  return data;
}
