var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"parent-child-wrapper",class:{shown: _vm.shown}},[_vm._t("default",function(){return [_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"customer-children",on:{"click":_vm.toggleDropdown}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","color":_vm.COLOR_TOOLTIP_GREY,"content-class":'customize-tooltip'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"child-name"},on),[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.getDisplayTitle.label))]),_c('div',{staticClass:"type"},[_vm._v(_vm._s(_vm.getDisplayTitle.type))])])]}},{key:"tooltip",fn:function(){return undefined},proxy:true}])},[_c('div',[_vm._v(_vm._s(_vm.getDisplayTitle.label)+" - "+_vm._s(_vm.getDisplayTitle.type))])]),(_vm.isOrganisationHasChild)?_c('simple-icon',{staticClass:"arrow-icon",attrs:{"icon":"arrow-toggle","padding":"3px","overrideColor":_vm.COLOR_PRIMARY_BLUE}}):_vm._e()],1)]}),(_vm.shown)?_c('div',{staticClass:"parent-child-dropdown"},[_c('div',{staticClass:"parent-child-dropdown-header"},[_vm._v("ACCOUNT")]),_c('dropdown-options-list',{staticClass:"parent-child-list",attrs:{"options":_vm.transformedData,"optionClass":_vm.optionClass},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_option = ref.option;
var label = ref_option.label;
var value = ref_option.value;
var type = ref_option.type;
var isSelected = ref_option.isSelected;
var i = ref.i;
return [(isSelected === true)?_c('simple-icon',{staticClass:"tick icon-circle",style:({backgroundColor: _vm.COLOR_PRIMARY_BLUE, borderRadius: '50%'}),attrs:{"icon":"f-pass","padding":"0px !important","size":"16px","overrideColor":"white"}}):_c('div',{staticClass:"icon-circle",style:({ width: '16px', height: '16px', borderRadius: '50%' , backgroundColor: _vm.COLOR__GREY_30})}),_c('div',{staticClass:"parent-child-dropdown-title"},[_c('div',{staticClass:"parent-child-label",class:_vm.addSelectedClass(isSelected)},[_vm._v(_vm._s(label))]),_c('div',{staticClass:"parent-child-type"},[_vm._v(_vm._s(type)+" ")])])]}}],null,false,2427934570),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }