import idNumber from "./fields/idNumberField";
import NationalListPickInput from "@/components/smart-inputs/NationalityListInput.vue";
import dateField from "./fields/dateField";
import { DISPLAY_DATE_FORMAT } from "@/constants";
import common from "@frankieone/shared";
import mergeBlueprint from "@/utils/mergeBlueprint.js";
import { formatDate } from "@/components/builders/documentForm/DocumentViewTranform.ts";
const { validator } = common;
let blueprint = {
  fields: {
    idNumber: {
      ...idNumber({ label: "Stock Number", mask: "###########" }),
      visibility() {
        return this.value.country === "AUS";
      },
      view() {
        return {
          title: "Stock Number",
          value: this.fieldValue
        };
      }
    },
    "extraData.acquisition_date": {
      ...dateField,
      visibility() {
        return this.value.country === "AUS";
      },
      props() {
        const inherited = dateField.props.bind(this)();
        return {
          ...inherited,
          placeholder: DISPLAY_DATE_FORMAT,
          label: "Date Of Acquisition",
          serialize: true,
          qa: "regDate"
        };
      },
      width() {
        return "25%";
      },
      view() {
        return {
          title: "Date Of Acquisition",
          value: formatDate(this.fieldValue, DISPLAY_DATE_FORMAT)
        };
      }
    },
    "extraData.primary_country": {
      component: NationalListPickInput,
      props() {
        return {
          label: this.value.country === "AUS" ? "Primary Country" : "Country Of Birth",
          value: { value: this.value?.extraData?.primary_country || "AUS" },
          returnSingleValue: true,
          qa: "primaryCountry"
        };
      },
      required() {
        return true;
      },
      visibility() {
        return true;
      },
      view() {
        return {
          title: this.value.country === "AUS" ? "Primary Country" : "Country Of Birth",
          value: this.country(this.fieldValue)
        };
      }
    },
    "extraData.secondary_country": {
      component: NationalListPickInput,
      props() {
        return {
          label: "Secondary Country",
          value: { value: this.value?.extraData?.secondary_country || "" },
          returnSingleValue: true,
          qa: "secondaryCountry"
        };
      },
      visibility() {
        return this.value.country === "AUS";
      },
      view() {
        return {
          title: "Secondary Country",
          value: this.country(this.fieldValue)
        };
      }
    }
  }
};

blueprint = mergeBlueprint("CITIZENSHIP", blueprint);

export { blueprint };
