import "./set-public-path";
import "../public/webfontsKit.css";
import Vue from "vue";
import App from "@/App.vue";
import init from "@/utils/commonInitializationActions";
import { getToken, getPageData } from "@/utils/pageDataStorage";

Vue.config.productionTip = false;

const injectedData = getPageData();
const vueLifecycles = init(injectedData, { rootComponent: App });

export const { bootstrap, mount, unmount } = vueLifecycles;
