import axios from "axios";
import routeToURLMapper from "@/utils/routeToUrlMapper";
import User from "@/model/User";
import extend from "@/utils/extendObject";
import config, { axiosConfigForNode } from "@/api/axiosConfig";
import { setPageData } from "@/utils/pageDataStorage";
export async function getUsers() {
  const url = routeToURLMapper("ffportal_user_list");

  return axios.get(url, axiosConfigForNode).then(response => {
    const users = response.data || [];
    return users.map(user => {
      return extend(new User(), user);
    });
  });
}
export async function getUserDetail(userId) {
  const url = routeToURLMapper("ffportal_user_detail", { userId });

  return axios.get(url, axiosConfigForNode).then(res => res.data.user);
}

export async function toggleLockUser(userId, locked) {
  const url = routeToURLMapper("ffportal_user_lock", { userId });
  return axios.put(url, { locked }, axiosConfigForNode);
}

export async function deleteUser(userId) {
  const url = routeToURLMapper("ffportal_user_delete", { userId });
  // const url = process.env.VUE_APP_PLAIN_DOMAIN + "/users/" + userId;

  return axios.delete(url, axiosConfigForNode);
}
export async function resetPassword(userName) {
  const resetPasswordRequestEndpoint = routeToURLMapper("ffportal_reset_password", {
    username: userName
  });
  const urlToPasswordResetForm = routeToURLMapper("ffportal_new_password_form");
  return axios.post(resetPasswordRequestEndpoint, { urlToPasswordResetForm }, { "Content-Type": "application/json" });
}

export async function addNewUser(data) {
  const url = routeToURLMapper("ffportal_user_create");
  const urlToPasswordResetForm = routeToURLMapper("ffportal_new_password_form");

  return axios.post(
    url,
    {
      userData: data,
      urlToPasswordResetForm
    },
    axiosConfigForNode
  );
}
export async function editUser(userId, data) {
  const url = routeToURLMapper("ffportal_user_edit.function", { userId });

  return axios.put(
    url,
    {
      userData: data,
      urlToPasswordResetForm: ""
    },
    axiosConfigForNode
  );
}

export async function setChildView(childUuid) {
  const url = routeToURLMapper("ffportal_user_child_view");
  return axios
    .put(
      url,
      {
        child_uuid: childUuid
      },
      axiosConfigForNode
    )
    .then(res => {
      setPageData(res.data);
      location.href = "/";
    });
}
