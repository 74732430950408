import StatePicker from "@/components/smart-inputs/StatePicker";

export default {
  component: StatePicker,
  props() {
    return {
      placeholder: "State",
      label: "State",
      shouldDisplay: this.value?.country === "AUS",
      qa: "region"
    };
  },
  view() {
    return {
      title: "State",
      value: this.state || ""
    };
  }
};
