import mapRouteToUrl from '@/utils/routeToUrlMapper'
import { FraudCheckResult } from '@frankieone/shared'
import axios from 'axios'
import { axiosConfigForNode } from '@/api/axiosConfig'

export async function getFraudCheckReport(entityId, options = {}) {
  const { ABORT_CONTROL, isKYCDeviceFraud = false } = Object.assign({}, options)
  const url = mapRouteToUrl(
    isKYCDeviceFraud
      ? 'ffportal_applicant_fraud_device'
      : 'ffportal_applicant_fraud',
    { entityId },
  )
  const { data } = await axios.get(url, {
    ...axiosConfigForNode,
    cancelToken: ABORT_CONTROL?.token,
  })
  if (isKYCDeviceFraud) {
    return data ?? { device: null, fraud: null }
  }
  return FraudCheckResult.fromJSON(data)
}

export async function overrideFraudListStatus(fraudListIds, payload) {
  const { entityId, comment, status, checkId } = Object.assign({}, payload)
  const url = mapRouteToUrl('ffportal_applicant_fraud_override_status', {
    entityId,
  })

  return axios.put(
    url,
    {
      comment,
      status,
      checkId,
      fraudListIds,
    },
    axiosConfigForNode,
  )
}
