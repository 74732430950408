import { aliasKeys } from "@/utils/formatters/aliasKeys";
import { STATE_ARCHIVED, STATE_INACTIVE, STATE_MANUAL_FAILED, STATE_MANUAL_PASSED } from "@/utils/stateBackendMapper";

export default function(label) {
  const STATES = { STATE_ARCHIVED, STATE_INACTIVE, STATE_MANUAL_FAILED, STATE_MANUAL_PASSED };
  const labels = {
    risk_low: "Risk Level Low",
    risk_medium: "Risk Level Medium",
    risk_high: "Risk Level High",
    risk_unacceptable: "Risk Level Unacceptable",
    risk_significant: "Risk Level Significant",
    risk_unknown: "Risk Level Unknown",
    wait: "Waiting on Customer",
    passed: "Passed",
    unchecked: "Unchecked",
    clear: "Clear",
    failed: "Failed",
    refer: "Needs Attention",
    watchlist: "Watchlist",
    duplicate: "Duplicate",
    blacklist: "Blocklist",
    media: "Adverse Media",
    pep: "PEP",
    "pep-class-1": "PEP Class 1",
    "pep-class-2": "PEP Class 2",
    "pep-class-3": "PEP Class 3",
    "pep-class-4": "PEP Class 4",
    "pep-class-5": "PEP Class 5",
    ...aliasKeys(["sanction", "sanctions"], "Sanctions"),
    ...aliasKeys(["aml", "ATT-NCMP", "att-ncmp"], "AML Error"),
    ...aliasKeys(["500", "error"], "System Error"),
    ...aliasKeys(["404", "partial"], "KYC"),
    [STATES.STATE_MANUAL_PASSED]: "Manually Passed",
    [STATES.STATE_MANUAL_FAILED]: "Manually Failed",
    [STATES.STATE_ARCHIVED]: "Archived",
    [STATES.STATE_INACTIVE]: "Inactive"
  };
  return labels[label] || label || "";
}
