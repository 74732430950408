<template lang="pug">
    .options-list( :class="[{ scroll, focused }]" data-qa="dropdown options list" )
        ul
            li.ellipsis(
                v-for="(opt, i) in options",
                :key="(opt.label+opt.value )",
                :class="[optionClass(opt, i), {selected: !multiple && isSelected(opt.value), 'is-empty': !opt.label || isEmpty(opt.value), 'cursor': cursorIndex === i }]",
                @click="clicked(opt)",
                @mouseover="cursorIndex = i"
            )
                  slot( v-if="$scopedSlots.default || $slots.default" :option="{...opt}" :i="i" :cursorIndex="cursorIndex" :selectedIndex="selectedIndex" )
                  template( v-else )
                    | {{ opt.label }}

</template>
<style lang="sass" scoped>
@import '~@/styles/scrollbar'
@import '~@/styles/config'

$border-style: 1px solid $COLOR__LIGHTGREY
.options-list
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12)
    $width: calc($LENGTH__SM_PADDING / 2)
    +style-scrollbar($width: $width)
    &.scroll
        max-height: 200px
        overflow-y: auto
        border-bottom-right-radius: $width
ul
    padding-left: 0!important
li
    padding: 8px 16px
    list-style: none
    width: 100%
    border: $border-style
    border-top: none
    cursor: pointer
    transition: background-color .2s ease-out
    background-color: white
    position: relative
    font-size: 14px
    line-height: 1.3

    &:nth-of-type(1)
        border-top: $border-style
    &.is-empty
        background-color: $COLOR__LIGHTGREY
        min-height: 1.4em
        line-height: 1em
        .name
            color: $COLOR__DARK!important
    &.italic
        font-style: italic
        font-weight: 400
    $selection-color: $COLOR__EXTRA_LIGHT_BLUE
    &.cursor
        color: $COLOR__BLACK
        background-color: $selection-color
.user-menu-option
    color: $COLOR__GREY_80
    border: unset
    &.cursor
        background-color: $COLOR__BACKGROUND_LIGHTGREY
    &:nth-of-type(1)
        border-top: unset
    &:nth-last-of-type(1)
        border-top: $border-style
.parent-child-option
    display: flex
    color: $COLOR__GREY_60
    border: unset
    padding: 6px 16px
    &.cursor
        background-color: $COLOR__BACKGROUND_LIGHTGREY
        color: $COLOR__GREY_60
    &:nth-of-type(1)
        border-top: unset
</style>
<style scoped>
/** crazy hack for safari */
@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .options-list {
      box-shadow: none !important;
    }
  }
}
</style>
<script>
import InputMixin from '@/components/inputs/Input.mixin'
import DropdownKeyboardNavigation from '@/mixins/navigation/DropdownKeyboardNavigation.mixin'

export default {
  mixins: [InputMixin, DropdownKeyboardNavigation],
  props: {
    // options: [{ label, value }]
    focused: Boolean,
    options: Array,
    scroll: Boolean,
    multiple: Boolean,
    optionClass: {
      type: Function,
      default: () => '',
    },
    emptyValues: {
      type: Array,
      default: () => ['', null, undefined],
    },
  },
  methods: {
    isEmpty(value) {
      return this.emptyValues.includes(value)
    },
    hasEmptyValue() {
      if (this.value === null || typeof this.value === 'undefined') return true
      if (typeof this.value.value !== 'undefined')
        return this.isEmpty(this.value.value)
      return this.isEmpty(this.value)
    },
    clicked(data) {
      this.$emit('input', data)
      this.$emit('click')
      // this.cursorIndex = null;
    },
    isSelected(optValue) {
      // if dropdown has no value, curr value is not selected
      if (!this.value) {
        return false
      }
      // if dropdown value is array, curr value is selected if it exists in value
      if (Array.isArray(this.value)) {
        return this.value.find(el => el.value === optValue)
      }
      // if dropdown value is object containing 'value' field, curr value is selected if equals to it
      if (typeof this.value.value !== 'undefined') {
        return this.value.value === optValue
      }

      // otherwise curr value is selected if equals dropdown value
      return this.value === optValue
    },
  },
}
</script>
