import { DISPLAY_DATE_FORMAT } from "@/constants";
import moment from "moment-timezone";

export function getDisplayTimeBuilder(timezone) {
  return (value, writeFormat, readFormat) => {
    writeFormat = writeFormat || DISPLAY_DATE_FORMAT;
    readFormat = readFormat || moment.ISO_8601;

    if (!value) return "";
    try {
      value = value.toString();

      const date = moment.utc(value, readFormat);
      if (timezone) {
        date.tz(timezone);
      }
      return date.format(writeFormat);
    } catch (e) {
      console.error("Error formatting time");
      console.error(e);
      return "";
    }
  };
}
