import { mkMutationName } from "@/store/helper";
import { setChildView } from "@/api/user";
import { getSettings, updateSettings } from "@/api/admin";

const SET_CHILD_ID = mkMutationName("set", "child_id");
const SET_SETTINGS = mkMutationName("set", "settings");

export default function({ pageData }) {
  // const currentCustomer = pageData.currentCustomer || {};
  const customerId = pageData?.organization?.customerId;
  const selectedChildId = pageData?.user?.activeChildCustomerId;
  return {
    state: {
      selectedChildId,
      customerId,
      isChildSelectionBusy: false,
      settings: null
    },
    mutations: {
      [SET_CHILD_ID](state, id) {
        state.selectedCustomerID = id;
      },
      [SET_SETTINGS](state, data) {
        state.settings = data;
      }
    },
    actions: {
      viewAsCustomerId({ state, rootState }, { id }) {
        if (state.customerId === id) id = null;
        state.isChildSelectionBusy = true;
        return setChildView(id)
          .then(() => {
            state.selectedChildId = id;
            rootState.applicants.applicantList.onboarding = null;
            rootState.applicants.applicantList.onboardingNextPageMarker = null;
            rootState.applicants.applicantList.monitoring = null;
            rootState.applicants.applicantList.monitoringNextPageMarker = null;
          })
          .finally(() => {
            state.isChildSelectionBusy = false;
          });
      },
      async getSettings({ commit }) {
        const data = await getSettings();
        commit(SET_SETTINGS, data);
      },
      async updateSettings({ commit, dispatch }, { name, value }) {
        await updateSettings({ name, value });
        dispatch("getSettings");
      }
    },
    getters: {
      isViewingAsWhichCustomerId: ({ selectedChildId, customerId }) => {
        if (!selectedChildId) return customerId;
        else return selectedChildId;
      },
      settings: ({ settings }) => {
        return settings;
      },
      setting: ({ settings }) => key => {
        return settings?.find(setting => setting.name === key)?.value;
      }
    }
  };
}
