import { DISPLAY_DATE_FORMAT } from "@/constants";
import { makeRequiredValidator, makeDateValidator } from "@/utils/validators/basicValidators";
import DateInput from "@/components/smart-inputs/DateInput";

export default {
  required() {
    return true;
  },
  props() {
    return {
      placeholder: DISPLAY_DATE_FORMAT,
      label: "Date Of Expiry",
      serialize: true,
      notShowDatePicker: true
    };
  },
  component: DateInput,
  width() {
    return "25%";
  }
};
