<template lang="pug">
    .parent-child-wrapper(:class='{shown}')
        slot
            .customer-children(@click='toggleDropdown' v-click-outside="clickOutside")
                v-tooltip(bottom open-delay='500' :color='COLOR_TOOLTIP_GREY' :content-class="'customize-tooltip'")
                    template(v-slot:activator="{ on }") 
                        .child-name(v-on="on")
                            .name {{getDisplayTitle.label}}
                            .type {{getDisplayTitle.type}}
                    template(v-slot:tooltip)
                    div {{getDisplayTitle.label}} - {{getDisplayTitle.type}}
                simple-icon.arrow-icon(v-if="isOrganisationHasChild" icon='arrow-toggle' padding='3px' :overrideColor='COLOR_PRIMARY_BLUE')
        .parent-child-dropdown(v-if='shown')
            .parent-child-dropdown-header ACCOUNT
            dropdown-options-list.parent-child-list( :options="transformedData" :optionClass='optionClass' v-model='selectedOption')
                template( v-slot="{option: {label, value, type, isSelected}, i}" )
                    simple-icon.tick.icon-circle(v-if="isSelected === true" icon="f-pass" padding="0px !important" size="16px" overrideColor="white" :style="{backgroundColor: COLOR_PRIMARY_BLUE, borderRadius: '50%'}" )
                    .icon-circle(v-else :style="{ width: '16px', height: '16px', borderRadius: '50%' , backgroundColor: COLOR__GREY_30}")
                    .parent-child-dropdown-title
                        div.parent-child-label( :class="addSelectedClass(isSelected)") {{ label }}
                        div.parent-child-type {{ type }} 
</template>
<script>
import {
  COLOR_PRIMARY_BLUE,
  COLOR_TOOLTIP_GREY,
  COLOR__GREY_30,
  COLOR__GREY_60,
} from '@/styles/config.sass'
import vClickOutside from 'v-click-outside'
export default {
  constants: {
    COLOR_TOOLTIP_GREY: COLOR_TOOLTIP_GREY,
    COLOR_PRIMARY_BLUE: COLOR_PRIMARY_BLUE,
    COLOR__GREY_30: COLOR__GREY_30,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    data: Object,
    selectedIds: {
      type: Array,
      default: () => [],
    },
    multiple: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    shown: false,
    temporarySelection: null,
    selectedOption: {
      label: '',
      value: '',
      type: '',
      isSeleted: '',
      click: null,
    },
  }),
  methods: {
    toggleDropdown() {
      // if its not shown and is disabled, dont toggle;
      if (!this.isOrganisationHasChild) return
      if (!this.shown && this.disabled) return
      this.shown = !this.shown
    },
    getItemValue(item) {
      return item.id
    },
    clickOutside() {
      if (this.multiple && this.shown) {
        this.$emit('input', this.temporarySelection)
        this.temporarySelection = null
      }
      if (this.shown) this.shown = false
    },
    getAccountType(isParent) {
      return isParent ? 'Parent account' : 'Child account'
    },
    optionClass() {
      return 'parent-child-option'
    },
    addSelectedClass(isSelected) {
      return isSelected ? 'item-selected' : ''
    },
  },
  computed: {
    isOrganisationHasChild() {
      return !!this.data?.items?.length
    },
    allSelectedIds: {
      get() {
        let values = []
        if (this.multiple && this.temporarySelection != null) {
          values = this.temporarySelection
        } else {
          values = this.selectedIds
        }
        if (!values) return []
        return values
      },
      set(values) {
        if (this.multiple) {
          this.temporarySelection = values
        } else {
          this.$emit('input', values)
          this.shown = false
        }
      },
    },
    transformedData() {
      const optionList = []

      const convertIntoOption = (dataItem, listId) => {
        const isSelected = listId.includes(dataItem.id)
        const type = this.getAccountType(dataItem.isParent)
        return {
          label: dataItem.title,
          value: dataItem.id,
          type,
          isSelected,
        }
      }

      optionList.push(convertIntoOption(this.data, this.allSelectedIds))

      if (this.data.items && this.data.items.length > 0) {
        this.data.items.forEach(item => {
          optionList.push(convertIntoOption(item, this.allSelectedIds))
        })
      }

      return optionList
    },
    getDisplayTitle() {
      const dataList = this.transformedData
      // Handler for the child login in order to not refactor the whole parent/child system
      return dataList.length === 1
        ? dataList[0]
        : dataList.find(item => item.isSelected)
    },
  },
  watch: {
    selectedOption: {
      handler(selected) {
        const selection = [...this.allSelectedIds]

        const addValue = value => selection.push(value)
        const removeValue = i => selection.splice(i, 1)
        const replaceValue = value => (selection[0] = value)
        const toggleValue = value => {
          const indexOfValue = selection.indexOf(value)
          // if value is already in the list, remove it
          if (indexOfValue !== -1) removeValue(indexOfValue)
          // if not yet, add to the list
          else addValue(value)
        }

        let action

        if (this.multiple) {
          action = toggleValue
        } else if (selection.length === 0) {
          action = addValue
        } else {
          action = replaceValue
        }
        action(selected.value)
        this.allSelectedIds = selection
      },
    },
  },
}
</script>
<style lang="sass" scoped>
$height: 32px
.customer-children
    display: flex
    align-items: center
    justify-content: space-between
    height: 36px
    padding: 4px 8px
    border-radius: 5px
    background-color: $COLOR__EXTRA_LIGHT_BLUE
    color: $COLOR_PRIMARY_BLUE
    cursor: pointer
    width: 148px
    .child-name
        display: flex
        flex: 0 1 auto
        flex-direction: column
        min-width: 0
        margin-right: 2px
    .arrow-icon
        flex: 0 0 auto
        transform: rotate(0)
    .type
        font-size: 10px
    .name
        overflow: hidden,
        text-overflow: ellipsis
        white-space: nowrap
        font-weight: 600
        font-size: 12px
.parent-child-wrapper
    position: relative
    min-width: 148px
    &:active
       outline: 3px solid $COLOR__BACKGROUND_LIGHTGREY
       border-radius: 4px
.parent-child-dropdown
    width: 224px
    right: 0px
    position: absolute
    background-color: $COLOR__WHITE
    padding: 16px 0
    z-index: 99999
    box-shadow: 0px 2px 4px -2px $COLOR__SHADOW_1, 0px 4px 6px -1px $COLOR__SHADOW_2
    border-radius: 4px
    margin-top: 17px
.parent-child-dropdown-header
    font-size: 12px
    font-weight: 500
    line-height: 16px
    color: $COLOR__GREY_80
    margin-bottom: 14px
    padding-left: 16px
.shown .arrow-icon
    transform: rotate(180deg)
.customize-tooltip
    max-width: 300px
    border-radius: 4px
    opacity: 1 !important
    overflow-wrap: break-word
    color: $COLOR__GREY_50
.customize-tooltip:after
    border-left: 5px solid transparent
    border-right: 5px solid transparent
    border-top: 5px solid black
    border-top-color: inherit
    left: calc(50% - 10px)
    top: -5px
    transform: rotate(180deg)
    position: absolute
    content: ''
.parent-child-list
    box-shadow: unset !important
    border: unset !important
.icon-circle
    border-radius: 50%
    flex: 0 0 auto
    margin-top: 1px
.parent-child-dropdown-title
    display: flex
    flex-direction: column
    margin-left: 10px
.parent-child-type
    font-size: 12px
    font-weight: 400
    line-height: 16px
    letter-spacing: 0em
    color: $COLOR__GREY_60
.parent-child-label
    word-break: break-word
    white-space: break-spaces
    font-weight: 600
.item-selected
    color: $COLOR__GREY_80 !important
</style>
