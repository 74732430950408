/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-avatar': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M500 10C229.3 10 10 229.3 10 500s219.3 490 490 490 490-219.3 490-490S770.5 10 500 10zm332.8 778.6C789.5 694.1 708.2 621 607.7 589.9c53.2-35 88.3-95.1 88.3-163.6 0-108.3-87.8-195.9-195.9-195.9s-195.9 87.8-195.9 195.9c0 68.4 35.1 128.4 88.3 163.6C292 620.8 210.7 694 167.4 788.6 99.9 711.2 58.9 610.5 58.9 500c0-243.6 197.4-440.9 440.9-440.9 243.6 0 440.9 197.4 440.9 440.9.2 110.5-40.7 211.2-107.9 288.6z"/>'
  }
})
