// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/ProximaNovaBold/font.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/ProximaNovaBold/font.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/ProximaNovaSemibold/font.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/ProximaNovaSemibold/font.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/ProximaNovaMedium/font.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./fonts/ProximaNovaMedium/font.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./fonts/ProximaNovaRegular/font.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./fonts/ProximaNovaRegular/font.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "*{font-family:proxima-nova,sans-serif!important;font-style:normal}@font-face{font-family:proxima-nova;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-display:auto;font-style:normal;font-weight:700}@font-face{font-family:proxima-nova;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-display:auto;font-style:normal;font-weight:600}@font-face{font-family:proxima-nova;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-display:auto;font-style:normal;font-weight:500}@font-face{font-family:proxima-nova;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\");font-display:auto;font-style:normal;font-weight:400}", ""]);
// Exports
module.exports = exports;
