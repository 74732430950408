import { mkMutationName } from "@/store/helper";
import { getApplicantBiometryReport } from "@/api/applicantOcr";
import { getDocumentScans } from "@/api/document";

export const setBiometryReportData = mkMutationName("set", "Biometri", "biometryReportData");
export const setDocumentDetailsData = mkMutationName("set", "Biometri", "documentDetailsData");
export const setDocumentScanBase64 = mkMutationName("set", "Biometri", "documentScanBase64");
export const setBiometryBase64 = mkMutationName("set", "Biometri", "biometryBase64");
export const setIdvOverrideResult = mkMutationName("set", "Biometri", "idvOverrideResult");
export const setFacialDuplicateResults = mkMutationName("set", "Biometri", "facialDuplicateResult");
export const clearCacheBIOMETRY = mkMutationName("clear", "Biometri", "allState");
export default {
  state: {
    biometryReportData: null,
    documentDetailsData: null,
    documentScanBase64: null,
    biometryBase64: null,
    idvOverrideResult: null,
    facialDuplicateResults: null
  },
  mutations: {
    [setBiometryReportData](state, data) {
      state.biometryReportData = data;
    },
    [setDocumentDetailsData](state, data) {
      state.documentDetailsData = data;
    },
    [setBiometryBase64](state, data) {
      state.biometryBase64 = data;
    },
    [setIdvOverrideResult](state, data) {
      state.idvOverrideResult = data;
    },
    [setFacialDuplicateResults](state, data) {
      state.facialDuplicateResults = data;
    },
    [clearCacheBIOMETRY](state) {
      state.biometryReportData = null;
      state.documentDetailsData = null;
      state.documentScanBase64 = null;
      state.biometryBase64 = null;
      state.idvOverrideResult = null;
      state.facialDuplicateResults = null;
    }
  },
  actions: {
    async getApplicantBiometryReport({ commit, getters }, payload) {
      const { entityId, ABORT_CONTROL } = Object.assign({ ABORT_CONTROL: {} }, payload);
      if (getters.isFetched_biometryReportData || !entityId) return;
      try {
        const response = await getApplicantBiometryReport(entityId, { ABORT_CONTROL });
        const {
          biometry,
          documentDetails,
          idvOverrideResult,
          facialDuplicateResult,
          applicantDetails,
          facialDuplicatesDetails
        } = response;
        const biometryReportData = biometry;
        const documentDetailsData = documentDetails;
        const facialDuplicateResults = { facialDuplicateResult, applicantDetails, facialDuplicatesDetails };

        if (biometryReportData.length > 0) {
          const documentId = biometryReportData?.[0]?.documentId;
          try {
            const response = await getDocumentScans(documentId);
            if (response) {
              const isVideo = scan => /video/.test(scan.mimeType);
              const isImage = scan => /image/.test(scan.mimeType);
              const video = response.find(isVideo);
              const image = response.find(isImage);
              commit(setBiometryBase64, { video, image });
            } else throw "no data";
          } catch (e) {
            commit(setBiometryBase64, false);
          }
        } else {
          commit(setBiometryBase64, false);
        }
        commit(setBiometryReportData, biometryReportData);
        commit(setDocumentDetailsData, documentDetailsData);
        commit(setIdvOverrideResult, idvOverrideResult);
        commit(setFacialDuplicateResults, facialDuplicateResults);
      } catch (e) {
        commit(setBiometryBase64, false);
        commit(setBiometryReportData, false);
        commit(setDocumentDetailsData, false);
      }
    }
  },
  getters: {
    //isDocumentReportFetchedData: state => !(state.logs === null),
    isFetched_documentDetailsData: state => state.documentDetailsData !== null,
    //isFetched_documentReportData: state => state.documentReportData !== null,
    isFetched_biometryReportData: state => state.biometryReportData !== null
  }
};
