import TextInput from "@/components/inputs/TextInput";

export default function(options) {
  const { label, placeholder, removeSpaces, mask, qa, id } = Object.assign(
    {
      removeSpaces: true,
      placeholder: "Number",
      label: "Number",
      mask: null,
      qa: "idNumberField",
      id: "idNumberField"
    },
    options
  );
  return {
    component: TextInput,
    props() {
      return {
        disabled: this.disabled,
        removeSpaces,
        placeholder,
        label,
        mask,
        qa,
        id
      };
    },
    visibility() {
      return true;
    },
    view() {
      return {
        title: label || "Licence Number",
        value: this.fieldValue
      };
    }
  };
}
