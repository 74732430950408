<template lang="pug">
  #portal-frame(:class="{'no-shadow': isReactVerticalNavBar}")
    portal-header(v-if="!isReactVerticalNavBar")
    slot

</template>
<script>
import PortalHeader from '@/frames/portal-frame/PortalHeader'
export default {
  components: {
    PortalHeader,
  },
  computed: {
    isReactVerticalNavBar() {
      // TODO: @ggrigorev NavBar remove global var
      window.isReactVerticalNavBar =
        this.pageData?.features?.verticalNavBar || false
      return this.pageData?.features?.verticalNavBar || false
    },
  },
}
</script>
<style lang="sass" scoped>
.no-shadow
  box-shadow: none !important
</style>
