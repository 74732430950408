<template lang="pug">
    button.rectangle-action( v-bind="attrs",
    v-on="listeners",
    @mouseover="isHover = true" @mouseleave="isHover = false"
    :style="{color, borderColor: color, height, lineHeight: height }"
    :class="{ disabled, outline, 'invalid': !validation, textButton, primary, primary800, secondary, ghostPrimary, ghostPrimaryRevert,  ghostSecondary, ghostColor, 'bt-warning': warning,  selected, icon, isHover, revertIcon }"
    :data-qa="qa" )
        frankie-icon(v-if="frankieIcon" :name="frankieIcon" color="#CDD0D5" :round="false" :size="iconSize || 18" :clickable="false")
        simple-icon(v-if="icon && !iconRight" :icon="icon" :size="`${iconSize}px`" padding="0" :style="iconStyle"
            :overrideColor="isHover ? iconHoverColor : iconOriginalColor" v-bind="iconAttrs")
        slot
        simple-icon(v-if="icon && iconRight" :icon="icon" :size="`${iconSize}px`" padding="0" :style="iconStyle"
            :overrideColor="isHover ? iconHoverColor : iconOriginalColor" v-bind="iconAttrs")
</template>

<style lang="sass" scoped>
button
    user-select: none
    border-radius: 4px
    border: 1px solid transparent
    background-color: $COLOR__DARK
    color: $COLOR__WHITE
    outline: none
    cursor: pointer
    transition: background-color .5s ease-out
    box-sizing: border-box
    padding: 0 $LENGTH__SM_PADDING
    font-weight: 700
    & * + *
        margin-left: 10px
    &.disabled
        background-color: $COLOR__MID_LIGHT_GREY
        cursor: not-allowed
    &.icon, .ff-icon
        background-color: white
        display: flex
        align-items: center
        justify-content: center
        padding: 0
        &:hover
            border: none
            background-color: $COLOR__BACKGROUND_GREY
    &.icon
        width: 32px
        height: 32px
    &.outline
        background-color: transparent
        color: $COLOR__DARK_NAVY
        border: 1px solid $COLOR__DARK_NAVY
        &:hover
            border-color: $COLOR__BLUE
        &.disabled
            border: 1px solid transparent
            color: white
            background: $COLOR__MID_LIGHT_GREY
    &.textButton
        background-color: transparent
        color: $COLOR__DARK_NAVY_60
        font-weight: bold
        &:hover
            color: $COLOR__BLUE!important
        &.disabled
            color: $COLOR__MID_LIGHT_GREY!important
            background: none!important
    &.grey
        background-color: $COLOR__TEXT_GREY
    &.invalid
        background-color: $COLOR__ACCENT !important
    &.primary
        background-color: $COLOR__BLUE!important
        border: none
        &:hover
            background-color: #376DD0!important
        &.disabled
            background-color: $COLOR__EXTRA_LIGHT_BLUE!important
    &.primary800
        background-color: $COLOR_PRIMARY_BLUE!important
        &:hover
            background-color: $COLOR_PRIMARY_BLUE_900!important
        &.disabled
            background-color: $COLOR__MID_LIGHT_GREY!important
    &.secondary
        background-color: $COLOR__DARK_NAVY!important
        border: none
        &:hover
            background-color: $COLOR__DARK_NAVY_80!important
        &.disabled
            background-color: $COLOR__MID_GREY!important
    &.ghostPrimary
        color: $COLOR__DARK_NAVY
        border-color: $COLOR__MID_LIGHT_GREY
        background-color: transparent!important
        &:hover
            color: $COLOR__BLUE
            border-color: $COLOR__BLUE
        &:disabled
            color: $COLOR__MID_GREY
            border-color: $COLOR__MID_GREY
        &.selected
            color: $COLOR__BLUE!important
            background-color: $COLOR__EXTRA_LIGHT_BLUE!important
            border-color: $COLOR__BLUE
    &.ghostPrimaryRevert
        color: white
        border-color: white
        background-color: transparent!important
        &:hover
            color: $COLOR__BLUE
            border: 1px solid $COLOR__BLUE!important
        &:disabled
            color: $COLOR__DARK_NAVY_80
            border-color: $COLOR__DARK_NAVY_80
    &.ghostSecondary
        color: $COLOR__NAVY
        border-color: $COLOR__MID_LIGHT_GREY
        background-color: transparent!important
        &:hover
            color: $COLOR__BLUE
            border-color: $COLOR__BLUE
        &:disabled
            color: $COLOR__MID_GREY
            border-color: $COLOR__MID_GREY
    &.bt-warning
        color: white
        background-color: $COLOR__RED
        &:disabled
            background-color: #FFD6D4
    &.ghostColor
        color: $COLOR__DARK_NAVY
        background-color: $COLOR__EXTRA_LIGHT_GREY
        border-color: $COLOR__MID_LIGHT_GREY
        align-items: center
        justify-content: center
        display: flex
        &:hover
            background-color: $COLOR__LIGHT_MID_GREY
        &:disabled
            border-color: $COLOR__MID_GREY
            color: $COLOR__MID_GREY
            background-color: white
    &.iconButton
        color: $COLOR__NAVY
        background-color: transparent

        &:hover
            color: $COLOR__BLUE
            background-color: #F4F5F7
    &.revertIcon
        flex-direction: row-reverse
        & * + *
            margin-right: 10px
            margin-left: 0px
    .ff-icon
        margin-right: 12px
        background-color: transparent
</style>

<script>
import InputMixin from '@/components/inputs/Input.mixin'
import SimpleIcon from '@/components/widgets/SimpleIcon.vue'
import { COLOR__DARK_NAVY_60, COLOR__BLUE } from '@/styles/config.sass'
export default {
  constants: {
    COLOR__DARK_NAVY_60: COLOR__DARK_NAVY_60,
  },
  mixins: [InputMixin],
  components: {
    SimpleIcon,
  },
  props: {
    outline: Boolean,
    textButton: Boolean,
    iconAttrs: Object,
    primary: Boolean,
    primary800: Boolean,
    secondary: Boolean,
    ghostPrimary: Boolean,
    ghostPrimaryRevert: Boolean,
    ghostSecondary: Boolean,
    warning: Boolean,
    color: String,
    ghostColor: Boolean,
    selected: Boolean,
    height: String,
    icon: String,
    revertIcon: Boolean,
    frankieIcon: String,
    iconSize: Number,
    qa: String,
    iconStyle: Object,
    iconRight: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isHover: false,
  }),
  computed: {
    iconHoverColor() {
      if (this.ghostPrimaryRevert) return COLOR__BLUE
      return (
        this.iconAttrs?.hoverColor ||
        this.iconAttrs?.overrideColor ||
        'ORIGINAL'
      )
    },
    iconOriginalColor() {
      if (this.ghostPrimaryRevert) return 'white'
      return this.iconAttrs?.overrideColor || 'ORIGINAL'
    },
  },
}
</script>
