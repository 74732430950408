<script>
import HasIssuesMixin from "./HasIssues.mixin";
import { makeRequiredValidator } from "@/utils/validators/basicValidators";
import flat from "flat";

export default {
  mixins: [HasIssuesMixin],
  props: {
    value: Object,
    triggerValidationOnDemand: Boolean
  },
  computed: {
    fieldsToValidate() {
      return Object.keys(flat(this.value || {}));
    },
    excludeFromValidation() {
      return [];
    },
    excludeRegexFromValidation() {
      return [];
    },
    validate_country() {
      const required = makeRequiredValidator();

      return value => required(value);
    }
  },
  methods: {
    triggerValidation() {
      const issues = [];

      this.fieldsToValidate.forEach(field => {
        if (this.excludeFromValidation.includes(field)) return;

        /* Excluded fields can be specified as regex. 
                This is especially relevant in profile form where all fileds of originalUbo need to be excluded from validation */
        for (let excludedRegex of this.excludeRegexFromValidation) {
          let reg = new RegExp(excludedRegex);
          if (reg.test(field)) {
            return;
          }
        }

        const validator = this.getValidator(field) || (() => true);
        const value = this.getField(field);
        const isValid = validator(value, field);

        if (!isValid) issues.push(field);
      });

      this.$emit("update:issues", issues);
    },
    getValidator(field) {
      let validator;
      const fullPath = this[`validate_${field}`];
      const fieldName = this[`validate_${field.split(".").pop()}`];
      switch (field) {
        default:
          validator = fieldName || fullPath || this["default_validator"];
      }

      return validator;
    },
    getFieldProps(field, options = {}) {
      const { valueAssessor, valueTransformer } = Object.assign(
        {},
        {
          // find value based on field. defaults to finding field in this.value
          valueAssessor: field => this.getField(field, this.value),
          // transform whatever value was found by assessor. Usually to be used with default assessor and transforming it to match what component expects. defaults to the value itself, as usually there's no need to transform
          // ex of use value => ({ value }) to insert it into inputs that expect objects { label, value } instead of just value
          valueTransformer: value => value
        },
        options
      );

      return {
        value: valueTransformer(valueAssessor(field)),
        validation: !this.hasIssues(field)
      };
    },
    getFieldEvents(field, options = {}) {
      const { valueTransformer } = Object.assign(
        {},
        {
          // transform whatever value was received. Usually to be transform value back to match what component expects. defaults to the value itself, as usually there's no need to transform
          // ex of use obj => obj.value to extract from @input in format { value } to simple value
          valueTransformer: value => value
        },
        options
      );

      return {
        input: $event => this.setField(field, valueTransformer($event))
      };
    },
    bundleValidators(validators) {
      /**
       * tests whole array of validators after any that returns false
       * if any returns false, return false
       */
      return value => !validators.some(validator => validator && !validator(value));
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function() {
        if (!this.triggerValidationOnDemand) this.triggerValidation();
      }
    },
    kycMethod(oldVal, newVal) {
      if (oldVal !== newVal) this.triggerValidation();
    }
  }
};
</script>
