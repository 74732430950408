import idNumber from "./fields/idNumberField";
import common from "@frankieone/shared";
import mergeBlueprint from "@/utils/mergeBlueprint.js";
const { validator } = common;
let blueprint = {
  fields: {
    idNumber: {
      ...idNumber({ label: "Licence Plate", placeholder: "Licence Plate" })
    }
  }
};
blueprint = mergeBlueprint("VEHICLE", blueprint);
export { blueprint };
