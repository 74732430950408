import axios from "axios";
import qs from "query-string";
import countries from "@/data/country";

export default class AddressFinderApi {
  constructor(key, secret) {
    this.key = key;
    this.secret = secret;
    this.base = "https://api.addressfinder.io/api/au/address";
  }
  __getAuthorizationHeader() {
    return {
      key: this.key
      // secret: this.secret DOESNT USE THE SECRET ON BROWSER CALLS
    };
  }
  async validateAddress(address) {
    const authorization = this.__getAuthorizationHeader(),
      params = qs.stringify({
        ...authorization,
        format: "json",
        max: 2,
        q: address
      }),
      url = `${this.base}/autocomplete?${params}`;
    const { data } = await axios.get(url);

    return data.completions && data.completions.length > 0;
  }
  async verifyAddress(address) {
    const authorization = this.__getAuthorizationHeader(),
      params = qs.stringify({
        ...authorization,
        format: "json",
        q: address
      }),
      url = `${this.base}/cleanse?${params}`;
    const { data } = await axios.get(url);

    return data.matched;
  }
  fetchCountryList() {
    try {
      return countries.map(e => ({ label: e.name, value: e.alpha3Code }));
    } catch (e) {
      return [];
    }
  }
  fetchCountryPhoneCodeList() {
    try {
      return countries.map(e => ({
        label: e.name + ` (+${e.callingCodes[0]})`,
        code: e.alpha3Code,
        value: e.callingCodes[0]
      }));
    } catch (e) {
      return [];
    }
  }
}
