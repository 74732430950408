<script>
import InputMixin from "@/components/inputs/Input.mixin";

export default {
  mixins: [InputMixin],
  computed: {
    attrs() {
      const { value, ...attrs } = { ...this.$attrs, ...this.$props };
      return attrs;
    }
  },
  data: () => ({
    validationState: true
  }),
  methods: {
    emitValue(value) {
      this.$emit("input", value);
    },
    copyValue() {
      return { ...this.value };
    }
  }
};
</script>
