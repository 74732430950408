<template lang="pug">
    .badge.color.has-tooltip( :style="{ backgroundColor, color, fontWeight }" :class="[`badge-type-${type}`, styleLike, { short, slim, wide, medium, fullname, 'fixed-width': fixedWidth }]" )
        span.tooltip.open-to-left( v-if="tooltipText" v-html="tooltipText" :style="{ backgroundColor, color, borderColor: backgroundColor  }" )
        slot( v-if="$slots.default" )
        template( v-else-if="label && !icon" )
            span.badge-label(v-if="label && !appendLabel") {{ label }}
            span.badge-label(v-else) {{ label }} {{ appendLabel  }}
        template.textual-icon-badge( v-else-if="label && icon" )
            //- svgicon( :name="icon" :color="color" width="16px" height="16px" )
            simple-icon.badge-icon( :icon="icon" overrideBGColor="transparent" :overrideColor="getColor(type)" :size="iconSize" padding="0px" )
            span.badge-icon-text.extra(v-if='extraText') {{extraText}}

</template>
<style lang="sass" scoped>
@import '~@/styles/config'
.badge
    position: relative
.extra
    padding: 0 5px
.badge-label
    font-weight: 700
    font-size: 14px
</style>

<script>
import WidgetColorMappings from "@/components/widgets/WidgetColorMappings";
import SimpleIcon from "@/components/widgets/SimpleIcon";

export default {
  mixins: [WidgetColorMappings],
  components: {
    SimpleIcon
  },
  props: {
    wide: Boolean,
    short: Boolean,
    slim: Boolean,
    medium: Boolean,
    fullname: Boolean,
    fixedWidth: Boolean,
    shortLabel: Boolean,
    appendLabel: String,
    iconSize: {
      type: String,
      default: "20px"
    }
  },
  computed: {
    icon() {
      let icon = null;

      if (this.label && this.label.icon) icon = this.label.icon;

      return icon;
    }
  },
  created() {
    if (this.icon) import(/* webpackChunkName: "[request]" */ `@/components/icons/${this.icon}.js`);
  }
};
</script>
