<script>
import isEmpty from "@/utils/isEmpty";

export default {
  inheritAttrs: false,
  props: {
    qa: String,
    id: String,
    label: String,
    value: {},
    // validation() === true means there's no error
    // typeof validation() === 'string' means there's an error message
    // validation() === false means there's an error without message
    validation: {
      type: [Boolean, Function],
      default: true
    },
    outline: Boolean,
    placeholder: String,
    masked: Boolean,
    mask: [String, Array, Boolean],
    autoValidation: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    removeSpaces: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isFocus: false
  }),
  // data: () => ({
  //     validationState: true
  // }),
  computed: {
    isValid() {
      return !(this.validation === false || typeof this.validation === "string");
    },
    qaLabel() {
      return this.qa || this.id || this.label || this.placeholder || "";
    },
    errorMessage() {
      const message = this.validation;
      const isString = typeof message === "string";
      return !this.isValid && isString ? message : null;
    },
    attrs() {
      const {
        // id,
        // label,
        // value,
        // validation,
        // placeholder,
        // autoValidation,

        ...attrs
      } = { ...this.$attrs, ...this.$props, "data-qa": this.qaLabel };
      return attrs;
    },
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    }
  },
  methods: {
    hasEmptyValue() {
      return isEmpty(this.value);
    },
    //         validate() {
    //             //  should alter validation state to correspond to true (has error, no message),
    //             // 'string' (has error, with message)
    //             // or false (no error)
    // console.log(this.validation)
    //             // if there is no value, don't validate
    //             if (!this.value || this.value === '')
    //                 return;

    //             let validation = this.validation
    //             const type = typeof this.validation;

    //             // if validation is of type 'function', pass value to function and keep result
    //             if (type === 'function')
    //                 validation = this.validation(this.value);
    //             // if it's type boolean, validation was outsourced, but has no message
    //             // if it's a string, it is a error message
    //             if (type === 'boolean' || type === 'string')
    //                 validation = this.validation;

    //             console.log(validation, this.validationState)
    //             this.validationState = validation;
    //             this.$emit('validationStateChange', validation);
    //         },
    emitValue($event) {
      //Handle trim data for the middle text
      let trimValue = $event.target.value;
      if (this.removeSpaces) {
        trimValue = trimValue.replace(/\s/g, "");
      }

      if (!this.disabled) this.$emit("input", trimValue);
    },
    emitTrimValue($event) {
      this.isFocus = false;
      //Handle trim data for the text in the start and end
      if (this.notTrimData) return;
      const trimValue = $event.target.value.trim();
      if (!this.disabled) this.$emit("input", trimValue);
    }
  }
  // watch: {
  //     value() {
  //         if (this.autoValidation)
  //             this.validate();
  //     }
  // }
};
</script>
