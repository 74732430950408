/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo': {
    width: 417,
    height: 159,
    viewBox: '0 0 417 159',
    data: '<g id="Frankie---Black-Logo---White-fon" _stroke="none" stroke-width="1" _fill="#000" fill-rule="nonzero"><path pid="0" id="f" d="M58 85.268V109H43V37h45v14H58v18.118a113.71 113.71 0 0 1 4.124-2.299c3.258-1.74 8.54-2.333 15.843-1.78v13.988c-8.476-.139-13.757.699-15.843 2.513A157.441 157.441 0 0 0 58 85.268z"/><path pid="1" id="r" d="M103 64.391c1.141-1.738 2.388-2.962 3.74-3.673 2.155-1.133 5.241-1.706 9.26-1.718v14c-2.63-.182-5.33.583-8.097 2.294-1.856 1.147-3.49 3.177-4.903 6.09V109H89V59h14v5.391z"/><path pid="2" id="a" d="M159 105.479A25.382 25.382 0 0 1 144.5 110c-14.083 0-25.5-11.417-25.5-25.5S130.417 59 144.5 59c5.387 0 10.383 1.67 14.5 4.521V59h14v50h-14v-3.521zM146.5 98c7.456 0 13.5-6.044 13.5-13.5S153.956 71 146.5 71 133 77.044 133 84.5 139.044 98 146.5 98z"/><path pid="3" id="n" d="M194 64.153c3.812-3.797 9.124-6.153 15-6.153 11.081 0 20.158 8.378 20.945 19H230v32h-14V78.427c-.865-4.745-5.538-8.427-11-8.427-5.452 0-10.101 4.134-10.981 8.5H194V109h-14V59h14v5.153z"/><path pid="4" id="k" d="M250 79.153l18.988-20.157h18.02l-21.089 21.707 21.089 28.307h-16.959l-13.426-18.967h-1.012L250 96.208V109h-14V37h14v42.153z"/><path pid="5" id="i" d="M291 59h14v50h-14V59zm6.5-7a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17z"/><path pid="6" id="e" d="M311.086 77.191c4.587-12.708 12.88-19.063 24.88-19.063 11.998 0 20.01 6.64 24.034 19.917.154 1.41.23 2.9.23 4.47 0 1.568-.076 3.064-.23 4.488l-34.804 3.876c3.592 3.98 7.516 6.07 11.773 6.273 4.257.202 8.606-1.496 13.048-5.094l7.764 9.334c-5.525 5.66-12.797 8.49-21.816 8.49-5.693 0-12.071-2.312-17.414-6.984-3.731-3.262-6.367-7.773-7.907-13.533a84.446 84.446 0 0 1-.25-6.366c0-2.041.23-3.977.692-5.808zm12.974 3.834l22.297-3.091v-1.015c-2.069-4.847-5.768-7.27-11.098-7.27-5.33 0-9.107 3.103-11.328 9.311l.129 2.065z"/><circle pid="7" id="dot" cx="376" cy="100" r="9"/></g>'
  }
})
