import { normalizeRiskData } from "@/utils/apiHelpers/normalizeRisk";

export const handleCheckSummaryData = function(cs) {
  let data = cs && cs.data;
  data = (data => {
    //if aleart list is empty return clear
    if (["passed", "pass_manual"].includes(data.status.type) && data.alertList.length === 0) {
      data.alertList = [
        {
          term: "clear",
          type: "success"
        }
      ];
      return data;
    }
    // alert mapper
    const bypassIssues = [];
    data.alertList = data.alertList.filter(issue => !bypassIssues.includes(issue.term.toLowerCase()));
    return data;
  })(data);

  data = (data => {
    // risk normalizer

    data.risk.normalized = normalizeRiskData(data.risk.class);
    return data;
  })(data);
  return data;
};
