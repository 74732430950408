<template lang="pug">
.v-toaster
    transition-group(name="v-toast")
        .v-toast(:class="{ [t.theme]: t.theme }", v-for="t in items", :key="t.key")
            simple-icon.icon(
                round,
                :icon="resolveToasterAttribute(t.theme).icon",
                size="20px",
                padding="0",
                :overrideBGColor="resolveToasterAttribute(t.theme).color",
                @click="remove(t)"
            )
            .message
                .f-body-bold(:class="t.hjSuppress.text && 'data-hj-suppress'") {{ t.message }}
                .f-body(:class="t.hjSuppress.subtext && 'data-hj-suppress'")
                    span(v-html="t.subtext")
                .f-body(v-if="t.footerBtn" :class="t.hjSuppress.footerBtn && 'data-hj-suppress'")
                    .footer-btn(@click="handleFooterBtnClick(t)" data-qa="toaster-footer-btn") {{ t.footerBtn.text }}
            .close-toaster
                simple-icon(
                    icon="f-fail",
                    clickable,
                    size="20px",
                    padding="0",
                    overrideColor="#CDD0D5",
                    @click="remove(t)"
                    data-qa="toaster-close-btn"
                )
</template>

<script>
import { COLOR__MID_LIGHT_GREY, COLOR__GREEN, COLOR__RED, COLOR__BLUE, COLOR__F_YELLOW } from "@/styles/config2.sass";
import { mkHtmlSanitiser } from "@frankieone/shared/dist/utils/sanitiser";
//This component clone from this library: github.com/paliari/v-toaster
export default {
  name: "Toaster",
  props: {
    timeout: {
      type: Number,
      default: 10000
    }
  },
  methods: {
    success(message, option = {}) {
      return this.add(message, { theme: "v-toast-success", ...option });
    },
    info(message, option = {}) {
      return this.add(message, { theme: "v-toast-info", ...option });
    },
    warning(message, option = {}) {
      return this.add(message, { theme: "v-toast-warning", ...option });
    },
    error(message, option = {}) {
      return this.add(message, { theme: "v-toast-error", ...option });
    },
    add(message, { theme, timeout, hjSuppress }) {
      const sanitiseHtml = value => {
        const sanitiser = mkHtmlSanitiser({ disallowedTagsMode: "discard" });
        const sanitise = sanitiser(value || "");
        return sanitise.replace(/&amp;/g, "&");
      };

      if (!this.$parent) {
        this.$mount();
        document.body.appendChild(this.$el);
      }

      let item = {};
      if (typeof message === "object") {
        const { text, subtext, footerBtn } = message;
        item = {
          message: sanitiseHtml(text),
          subtext,
          footerBtn,
          theme,
          key: `${Date.now()}-${Math.random()}`,
          hjSuppress: {
            text: hjSuppress?.text,
            subtext: hjSuppress?.subtext,
            footerBtn: hjSuppress?.footerBtn,
          }
        };
      } else if (typeof message === "string") {
        item = {
          message: sanitiseHtml(message),
          theme,
          key: `${Date.now()}-${Math.random()}`,
          hjSuppress: { text: hjSuppress?.text },
        };
      }

      this.items.push(item);
      setTimeout(() => this.remove(item), timeout || this.timeout);

      return item
    },
    remove(item) {
      const i = this.items.indexOf(item);
      if (i >= 0) {
        this.items.splice(i, 1);
      }
    },
    resolveToasterAttribute(theme) {
      let color = "";
      let icon = "";
      switch (theme) {
        case "v-toast-success":
          color = COLOR__GREEN;
          icon = "f-pass";
          break;
        case "v-toast-error":
          color = COLOR__RED;
          icon = "f-fail";
          break;
        case "v-toast-warning":
          color = COLOR__F_YELLOW;
          icon = "f-info";
          break;
        case "v-toast-info":
          color = COLOR__BLUE;
          icon = "f-info";
          break;

        default:
          break;
      }
      return { color, icon };
    },
    handleFooterBtnClick(t) {
      t.footerBtn.action();
      if (t.footerBtn.closeOnClick) {
        this.remove(t);
      }
    }
  },
  data() {
    return {
      items: []
    };
  }
};
</script>

<style lang="sass" scope>
$dark-color: #454d5d

=toast-variant($color)
    color: white
    background: $COLOR__DARK_NAVY_80
.v-toaster
    position: fixed
    bottom: 70px
    right: 0
    z-index: 10000
    width: 400px
    min-height: 0
    padding-left: 10px
    padding-right: 10px

.v-toast
    margin-bottom: 10px
    transition: all 0.3s ease
    border-radius: 4px
    color: #fff
    display: flex
    align-items: flex-start
    justify-content: flex-start
    padding: 1rem
    box-shadow: 0px 0px 20px rgba(158, 164, 170, 0.5)
    position: relative
    .message
        margin-left: 12px
        margin-right: 12px
    &.v-toast-enter, &.v-toast-leave-to
        -webkit-transform: translate(100%)
        transform: translate(100%)

    &.v-toast-success
        +toast-variant($COLOR__GREEN)

    &.v-toast-warning
        +toast-variant($COLOR__F_YELLOW)

    &.v-toast-info
        +toast-variant($COLOR__BLUE)

    &.v-toast-error
        +toast-variant($COLOR__RED)

    &.v-toast-primary
        +toast-variant(#428bca)

    .icon
        flex-shrink: 0
.close-toaster
    position: absolute
    right: 12px
    top: 6px
@media (max-width: 300px)
    .v-toaster
        width: 100%
.footer-btn
    font-weight: 700
    cursor: pointer
</style>
