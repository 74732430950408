<template lang="pug">
label-wrapper(:label="label")
    CheckboxGroup.wrapper-checkbox(
        :options="options",
        :value="resolveMiddleNameType",
        @input="handleSelectMiddleName",
        :disabled="disabled",
        :class="{}"
    )
</template>
<script>
import LabelWrapper from "@/components/wrappers/LabelMessageWrapper";
import { CheckboxGroup } from "@frankieone/ui-components";
export default {
  components: { CheckboxGroup, LabelWrapper },
  props: {
    value: String,
    label: String,
    middleName: String,
    disabled: Boolean
  },
  computed: {
    options() {
      const disabled = this.disabled;
      return [
        { value: "full-middle-name", label: "Full Middle Name", disabled },
        { value: "no-middle-name", label: "No Middle Name" },
        { value: "middle-initial-only", label: "Middle Initial Only", disabled }
      ];
    },
    resolveMiddleNameType() {
      const length = this.value?.length;
      if (length === 1) return "middle-initial-only";
      else if (length > 1) return "full-middle-name";
      else return "no-middle-name";
    }
  },
  methods: {
    handleSelectMiddleName(option) {
      let resolveMiddleName = "";
      switch (option) {
        case "full-middle-name":
          resolveMiddleName = this.middleName;
          break;
        case "no-middle-name":
          resolveMiddleName = "";
          break;
        case "middle-initial-only":
          resolveMiddleName = this.middleName[0];
          break;

        default:
          break;
      }
      this.$nextTick(() => this.$emit("input", resolveMiddleName));
    }
  },
  watch: {
    middleName: function(newMiddleName) {
      if (newMiddleName === "") {
        this.handleSelectMiddleName("no-middle-name");
      }
    }
  }
};
</script>
<style lang="sass" scoped>
.wrapper-checkbox
    &::v-deep
        .the-block
            text-align: center
            font-weight: 600
        .ff-disabled .the-block .ff-label
            color: #CDD0D5!important
        .ff-disabled  *
            cursor: not-allowed
</style>
