import { getPageData } from "@/utils/pageDataStorage";
import { isFeatureEnabled } from "@/utils/featureflag";
import { isBoolean } from "validate.js";

export const TXN_PERMISSIONS = {
  AML: "ffportal_applicant_fetch_aml_transaction_data",
  FRAUD: "ffportal_applicant_fetch_fraud_transaction_data",
  CUSTOMER: "ffportal_applicant_fetch_device_transaction_data"
};

export const isTxnPermission = (permission: string): boolean => {
  return !!/(ffportal_applicant_fetch_)(.*)(_transaction_data)/g.test(permission);
};

export const filterTxnPermissions = (permissions: string[]): string[] => {
  return permissions.filter(permission => isTxnPermission(permission));
};

/**
 * @deprecated Use hasAnyTxnFeature instead
 * new feature flag is in the format of {aml: boolean, fraud: boolean, device: boolean}
 * @see {hasAnyTxnFeature}
 */
export const hasTxnFeature = (): boolean => {
  return isFeatureEnabled("transaction-monitoring") || !!getPageData()?.features?.transactionMonitoring;
};

type TxnFeatureFlag = {
  hasAml: boolean;
  hasFraud: boolean;
  hasDevice: boolean;
}

/**
 * Transaction Monitoring Feature Flag can either be a boolean or an object with the following properties:
 * if boolean and true then all features are enabled
 * if object then each property represents a feature
 */
export const getTxnFeatureFlag = (): TxnFeatureFlag => {
  const defaultTxnFeatureFlag: TxnFeatureFlag = {
    hasAml: false,
    hasFraud: false,
    hasDevice: false,
  }

  const pageDataTxnFeatureFlag = getPageData()?.features?.transactionMonitoring

  if (pageDataTxnFeatureFlag) {
    if (!isBoolean(pageDataTxnFeatureFlag)) {
      return {
        ...defaultTxnFeatureFlag,
        hasAml: pageDataTxnFeatureFlag.aml,
        hasFraud: pageDataTxnFeatureFlag.fraud,
        hasDevice: pageDataTxnFeatureFlag.device,
      } as TxnFeatureFlag
    } else {
      return {
        hasAml: pageDataTxnFeatureFlag,
        hasFraud: pageDataTxnFeatureFlag,
        hasDevice: pageDataTxnFeatureFlag,
      } as TxnFeatureFlag
    }
  }

  return defaultTxnFeatureFlag
} 

/**
 * Updated version of hasTxnFeature
 * @returns  true if any of the transaction monitoring features are enabled or if the old feature flag (boolean) is enabled
 */
export const hasAnyTxnFeature = (): boolean => {
  const pageDataTxnFeatureFlag = getPageData()?.features?.transactionMonitoring

  if (pageDataTxnFeatureFlag) {
    if (!isBoolean(pageDataTxnFeatureFlag)) {
      return (
        pageDataTxnFeatureFlag.aml ||
        pageDataTxnFeatureFlag.fraud ||
        pageDataTxnFeatureFlag.device
      )
    } else {
      return pageDataTxnFeatureFlag
    }
  }
}

export const FRAUD_FACTORS_NAME = {
  Fraud: 'Transactions',
  Device: 'Device/Customer',
}

export const hasTxnPermissionAML = (): boolean => {
  const { hasAml } = getTxnFeatureFlag()
  return !!getPageData()?.permissions?.includes(TXN_PERMISSIONS.AML) && hasAml;
};

export const hasTxnPermissionFraud = (): boolean => {
  const { hasFraud } = getTxnFeatureFlag()
  return !!getPageData()?.permissions?.includes(TXN_PERMISSIONS.FRAUD) && hasFraud;
};

export const hasTxnPermissionCustomer = (): boolean => {
  const { hasDevice } = getTxnFeatureFlag()
  return !!getPageData()?.permissions?.includes(TXN_PERMISSIONS.CUSTOMER) && hasDevice;
};

export const hasAnyTxnPermissions = (permissions: string[]): boolean => {
  return !!filterTxnPermissions(permissions).length;
};
