import isObj from "isobject";
import extendObject from "lodash.mergewith";
const isArr = Array.isArray;
/**
 *
 * @param {Initial Object} a
 * @param {New data to be merged} b
 * @param {Function to evaluate values before merging. Returns new merged value.} customizer
 *
 * This function merges two objects and by default it prevents the final object to have null values, unless the initial value is also null
 */
export default function(
  a,
  b,
  customizer = (a, b) => {
    if (b === null) return a;
    if (isObj(a) || isObj(b) || isArr(a) || isArr(b)) return;

    const isNull = ["null", undefined, null].includes(b);
    // if b is not in the list, return its value
    // otherwise, return a
    return isNull ? a : b;
  }
) {
  return extendObject(a, b, customizer);
}
