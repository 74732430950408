<template lang="pug">
    .round-toggle-input( :class="{ selected: value }" @click.stop="select" :data-qa="qaLabel" )
        span( :style="{ width: size, height: size }" )
</template>
<style lang="sass" scoped>
@import '~@/styles/config'
.round-toggle-input
    span
        cursor: pointer
        position: relative
        display: block
        background-color: transparent
        border-radius: 50%
        border: 1px solid grey

        &:before
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%) scale(0)
            transform-origin: center center
            transition-property: transform, box-shadow
            transition-duration: .4s
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55)
            height: 80%
            width: 80%
            background-color: $COLOR__ACCENT
            border-radius: 50%

    &.selected
        span
            box-shadow: 2px 2px 10px $COLOR__SHADOW
            &:before
                transform: translate(-50%, -50%) scale(1) !important
</style>

<script>
import InputMixin from "@/components/inputs/Input.mixin";
export default {
  mixins: [InputMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "20px"
    }
  },
  methods: {
    select() {
      this.$emit("input", !this.value);
    }
  }
};
</script>
