





























































import '@/components/icons/user-avatar'
import '@/components/icons/arrow-toggle'
import ToggleMixin from '@/components/inputs/Toggle.mixin'
import routeToUrl from '@/utils/routeToUrlMapper'
import mapRouteToUrl from '@/utils/routeToUrlMapper'
import { COLOR__BLUE } from '@/styles/config2.sass'
import Vue from 'vue'
import { getToken } from '@/utils/pageDataStorage'

export default Vue.extend({
  constants: { COLOR__BLUE },
  mixins: [ToggleMixin],
  props: {
    usernameDisplayIcon: {
      type: String,
    },
  },
  data: () => ({
    selectedOption: { label: '', value: '', click: null },
  }),
  computed: {
    isInternalUser() {
      const organizationName = this.pageData.organization?.name ?? ''
      return organizationName.toLowerCase().includes('frankie')
    },
    userOptions() {
      type MenuOption = {
        label: string
        value: string
        click?: () => void
        isLink?: boolean
        testId: string
      }

      let smartUiOption: MenuOption = {
        label: 'Start Smart UI Demo',
        value:
          process.env.VUE_APP_ONBOARDING_WIDGET_URL +
          '&profile=default&environment=v4',
        isLink: true,
        testId: 'user-menu-option-smart-ui',
      }

      const defaultOptions: MenuOption[] = [
        {
          label: 'Settings',
          value: 'settings',
          click: () => {
            window.location.href = mapRouteToUrl('ffportal_settings_mfa')
          },
          testId: 'user-menu-option-settings',
        },
        {
          label: 'Change Password',
          value: 'change-password',
          click: () => {
            localStorage.removeItem('token')
            localStorage.removeItem('pageData')
            const resetPasswordPageURL = mapRouteToUrl(
              'ffportal_reset_password_request',
              {
                username: this.pageData.user.username,
              },
            )
            window.location.href = resetPasswordPageURL
          },
          testId: 'user-menu-option-change-password',
        },
        {
          label: 'Logout',
          value: 'logout',
          click: () => {
            // Clear local storage of the current domain before calling logout. Clearing data is also handled on login redirect.
            // But we're seeing situations where the user logs out from latest.frankiefinancial and the redirect login is
            // portal.latest.frankiefinancial. In this case local storage under latest.frankiefinancial does not get cleared
            // on redirect login
            let token = getToken()
            localStorage.removeItem('token')
            localStorage.removeItem('pageData')
            window.location.href = routeToUrl('bff_logout', { token: token })
          },
          testId: 'user-menu-option-logout',
        },
      ]

      if (this.isInternalUser) defaultOptions.splice(1, 0, smartUiOption)

      return defaultOptions
    },
  },
  methods: {
    requestPasswordReset() {},
    optionClass() {
      return 'user-menu-option'
    },
  },
  watch: {
    selectedOption: {
      handler(selected) {
        const option = this.userOptions.find(o => o.value === selected.value)

        if (option && option.click) option.click()

        this.userOption = { label: '', value: '', click: null }
      },
    },
  },
})
