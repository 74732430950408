import routeToURLMapper from "@/utils/routeToUrlMapper";
import axios, { CancelTokenSource } from "axios";
import { axiosConfigForNode } from "./axiosConfig";

export interface RequestOptions {
  ABORT_CONTROL?: CancelTokenSource;
  version?: string;
  limit?: number;
  appendList?: boolean;
  queryString?: string;
  [filter: string]: any;
}
export async function getOwnershipDetails(entityId, options: RequestOptions) {
  const ABORT_CONTROL = options?.ABORT_CONTROL || { token: undefined };
  const url = routeToURLMapper("ffportal_business_ownership_details", { entityId });
  return axios.get(url, { ...axiosConfigForNode, cancelToken: ABORT_CONTROL.token }).then(res => {
    return res.data;
  });
}
