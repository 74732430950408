import Vue from "vue";
import Vuetify from "vuetify";
import styles from "@/styles/config.sass";
import "vuetify/dist/vuetify.min.css";
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: styles.COLOR__BLUE,
        secondary: styles.COLOR__DARK_NAVY,
        accent: styles.COLOR__ACCENT,
        error: styles.COLOR__ACCENT,
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
});
